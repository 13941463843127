import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import preLoaderReducer from './slices/preLoaderSlice';
import formReducer from './slices/formSlice';

export default configureStore({
    reducer: {
        authReducer,
        preLoaderReducer,
        formReducer
    }
});