import styles from './business.module.css';
import { Card } from "../card/Card";
import { InputField } from '../forms/inputField/InputField';
import { SelectBox } from '../forms/selectBox/SelectBox';
import { Button } from '../forms/button/Button';
import { useEffect, useState } from 'react';
import { getRoundedCheckIcon } from '../../helpers/iconUtils';
import { isValidIndianMobileNumber, makeAPICall } from '../../helpers/utils';
import { useDispatch } from 'react-redux';
import { setIsLoading } from "../../redux/slices/preLoaderSlice";
import toast from "react-hot-toast";
import { setFormData } from '../../redux/slices/formSlice';

export const Business = () => {
    const dispatch = useDispatch();

    // business card fields
    const printerWidthOptions = [
        { id: 0, value: 'Thermal Printer - 72mm', key: '72mm' },
        { id: 1, value: 'A0 - 841mm', key: '841mm' },
        { id: 2, value: 'A1 - 594mm', key: '594mm' },
        { id: 3, value: 'A2 - 420mm', key: '420mm' },
        { id: 4, value: 'A3 - 297mm', key: '297mm' },
        { id: 5, value: 'A4 - 210mm', key: '210mm' },
        { id: 6, value: 'A5 - 148mm', key: '148mm' },
        { id: 7, value: 'A6 - 105mm', key: '105mm' },
        { id: 8, value: 'A7 - 74mm', key: '74mm' },
        { id: 9, value: 'A8 - 52mm', key: '52mm' },
        { id: 10, value: 'A9 - 37mm', key: '37mm' },
        { id: 11, value: 'A10 - 26mm', key: '26mm' },
    ];
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [GSTNo, setGSTNo] = useState('');
    const [printerWidth, setPrinterWidth] = useState(printerWidthOptions[0].key);
    const [saveReceipts, setSaveReceipts] = useState(true);
    const [cgst, setCgst] = useState('');
    const [sgst, setSgst] = useState('');
    const [gst, setGst] = useState('');

    // Errors
    const [errors, setErrors] = useState({});

    // Business
    const [business, setBusiness] = useState({});

    // Get business
    const getBusiness = async () => {
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/business`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            setBusiness(response.data);
        } else {
            toast.error('Something went wrong while fetching business data.');
        }
    };

    // Save business
    const saveBusiness = async () => {
        if (name?.length === 0 ||
            address?.length === 0 ||
            !isValidIndianMobileNumber(phone)
        ) {
            toast.error('Either business name, address or phone is invalid.');
            return;
        } else if (GSTNo?.length > 0 && GSTNo?.length !== 15) {
            toast.error('Please enter correct GST number or leave it blank.');
            return;
        } else if (
            (cgst !== '' && parseFloat(cgst) <= 0 || parseFloat(cgst) > 100) ||
            (sgst !== '' && parseFloat(sgst) <= 0 || parseFloat(sgst) > 100)
        ) {
            toast.error('GST value must be between [0-100] or leave it blank.');
            return;
        }
        const { response, error } = await makeAPICall({
            type: business.name ? 'put' : 'post',
            endpoint: `/business`,
            payload: {
                name,
                address,
                phone,
                gstNo: GSTNo,
                cgst,
                sgst,
                saveReceipts,
                paperSize: printerWidth || printerWidthOptions[0].key
            },
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            dispatch(setFormData({}));
            toast.success('Business data got saved successfully.');
        } else {
            toast.error(error.message || error || 'Something went wrong while saving business.');
        }
    };

    useEffect(() => {
        getBusiness();
    }, []);

    useEffect(() => {
        setName(business.name);
        setAddress(business.address);
        setPhone(business.phone);
        setGSTNo(business.gstNo);
        setCgst(business.cgst);
        setSgst(business.sgst);
        if (business.cgst && business.sgst) {
            setGst(parseFloat(business.cgst) + parseFloat(business.sgst));
        }
        setPrinterWidth(business?.paperSize || printerWidthOptions[0].key);
        if (!business?.name || business?.name === '') {
            setSaveReceipts(true);
        } else {
            setSaveReceipts(business?.saveReceipts === 1 ? true : false);
        }
    }, [business]);

    useEffect(() => {
        let half = parseFloat(gst / 2).toFixed();
        half = half == 0 ? '' : half;
        setCgst(half);
        setSgst(half);
    }, [gst]);

    return (
        <>
            {/* Business */}
            <Card
                centered={true}
                cardHeader={<div className={styles.card_title}>
                    {getRoundedCheckIcon()}
                    <span>Business Details</span>
                </div>}>
                <InputField
                    type={'text'}
                    label={'Business Name'}
                    placeholder={'Enter business name'}
                    required={true}
                    name={'name'}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                <InputField
                    type={'text'}
                    label={'Address'}
                    placeholder={'Enter business address'}
                    required={true}
                    name={'address'}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                <InputField
                    type={'number'}
                    label={'Phone'}
                    placeholder={'Enter business contact number'}
                    required={true}
                    name={'phone'}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                <InputField
                    type={'text'}
                    label={'GST Number'}
                    placeholder={'Enter business GST number'}
                    required={false}
                    name={'GSTNo'}
                    value={GSTNo}
                    onChange={(e) => setGSTNo(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                {/* <InputField
                    type={'number'}
                    label={'CGST (%)'}
                    placeholder={'Enter CGST percentage (Eg - 5, 14, 18 etc)'}
                    required={false}
                    name={'cgst'}
                    value={cgst}
                    onChange={(e) => setCgst(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                /> */}
                {/* <InputField
                    type={'number'}
                    label={'SGST (%)'}
                    placeholder={'Enter SGST percentage (Eg - 5, 14, 18 etc)'}
                    required={false}
                    name={'sgst'}
                    value={sgst}
                    onChange={(e) => setSgst(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                /> */}
                <InputField
                    type={'number'}
                    label={'GST on total amount (%)'}
                    placeholder={'Enter GST percentage (Eg - 5, 14, 18 etc)'}
                    required={false}
                    name={'gst'}
                    value={gst}
                    onChange={(e) => setGst(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                <SelectBox
                    label={'Select paper size'}
                    name={'printerWidth'}
                    required={true}
                    onChange={(e) => setPrinterWidth(e.target.value)}
                    value={printerWidth}
                    options={printerWidthOptions}
                />
                <InputField
                    type={'checkbox'}
                    label={'Allow us to save your receipts.'}
                    required={true}
                    name={'saveReceipts'}
                    value={saveReceipts}
                    checked={saveReceipts}
                    onChange={() => setSaveReceipts(!saveReceipts)}
                    errors={errors}
                    autoComplete={'none'}
                />
                <Button
                    type={'primary'}
                    text={'Save business'}
                    onClick={saveBusiness}
                />
            </Card>
        </>
    );
};