import { useEffect } from "react";
import { useAuth } from "../../customHooks/useAuth";

export const Logout = () => {
    const { doLogout } = useAuth();

    useEffect(() => {
        const logout = async () => {
            await doLogout();
        };
        // Log out
        logout();
    }, []);

    return (
        <p>Logging you out ...</p>
    );
};