import { createSlice } from '@reduxjs/toolkit';

export const formSlice = createSlice({
    name: 'formSlice',
    initialState: {
        formData: {}
    },
    reducers: {
        setFormData: (state, action) => {
            state.formData = action.payload;
        },
        updateFormData: (state, action) => {
            state.formData = { ...state.formData, ...action.payload };
        },
        clearFormData: (state) => {
            state.formData = { ...state.formData, receiptData: { rowData: [] } };
        },
    }
});

// Export actions
export const { setFormData, updateFormData, clearFormData } = formSlice.actions;

// Export reducer
export default formSlice.reducer;