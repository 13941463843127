import { login, logout } from "../redux/slices/authSlice";
import { setIsLoading } from "../redux/slices/preLoaderSlice";
import { setFormData } from "../redux/slices/formSlice";
import { fetchWrapper } from "../helpers/utils";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let timeoutId = null;

    // Refresh access token
    const refreshToken = () => {
        // Clear timeout if present
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Get user info
        const userInfo = JSON.parse(localStorage.getItem('__userInfo'));

        // Checks
        // If userInfo not found in localStorage or refresh token expired
        if (!userInfo ||
            new Date().getTime() > userInfo?.refreshTokenIssuedAt + userInfo?.refreshTokenExpiresIn
        ) {
            // Remove from localStorage
            localStorage.removeItem('__userInfo');

            // Remove from redux
            dispatch(logout());

            return;
        }

        // Get refresh token
        const refreshToken = userInfo.refreshToken;

        // Make API call to token endpoint
        fetchWrapper.post('/auth/token', { refreshToken })
            .then(response => {
                if (response.success) {
                    // Old user info
                    const oldUserInfo = JSON.parse(localStorage.getItem('__userInfo')) || {};

                    // Dispatch user data to redux
                    dispatch(login({ ...oldUserInfo, ...response.data }));

                    // Save userInfo in localStorage
                    localStorage.setItem('__userInfo', JSON.stringify({ ...oldUserInfo, ...response.data }));

                    // Again set up silent refresh
                    setupForSilentRefresh();

                    // Show success message
                    console.log('Silent refresh successfull.');
                }
            })
            .catch(error => {
                if (error && error.statusCode === 403) {
                    // Log error
                    console.log(error);

                    // Remove from localStorage
                    localStorage.removeItem('__userInfo');
                } else {
                    // Log generic error
                    console.log(error || 'Something went wrong while silent refresh.');
                }

                // Remove existing user data from redux & logout
                dispatch(logout());

                // Remove form data
                dispatch(setFormData({}));
            });
    };

    // Setup silent refresh
    const setupForSilentRefresh = () => {
        const userInfo = JSON.parse(localStorage.getItem('__userInfo'));
        if (!userInfo) return;

        const accessTokenIssuedAt = userInfo.accessTokenIssuedAt;
        const accessTokenExpiresIn = userInfo.accessTokenExpiresIn;
        const isAccessTokenExpired = new Date().getTime() > (accessTokenIssuedAt + accessTokenExpiresIn);

        // If access token not expired, update redux store
        if (!isAccessTokenExpired) {
            dispatch(login(userInfo));
        }

        // Timing for token refresh
        const tokenRefreshDuration = isAccessTokenExpired ? 0 :
            ((accessTokenIssuedAt + accessTokenExpiresIn) - new Date().getTime() - 5000);

        // Set timeout
        timeoutId = setTimeout(() => {
            refreshToken();
        }, tokenRefreshDuration);
    };

    // Do login
    const doLogin = async (userName, password) => {
        // Enable loader
        dispatch(setIsLoading(true));

        // Make API call to login endpoint
        fetchWrapper.post('/auth/login', { userName, password })
            .then(response => {
                if (response.success) {
                    // Dispatch user data to redux
                    dispatch(login(response.data));

                    // Save userInfo in localStorage
                    localStorage.setItem('__userInfo', JSON.stringify(response.data));

                    // Setup silent refresh
                    setupForSilentRefresh();

                    // Show success message
                    toast.success(response.message || 'Login successfull.');
                }
            })
            .catch(error => {
                console.log(error);
                toast.error(error.message || error || 'Something went wrong while logging in.');
            }).finally(() => {
                // Disable loader
                dispatch(setIsLoading(false));
            });
    }

    // Do logout
    const doLogout = async () => {
        // Enable loader
        dispatch(setIsLoading(true));

        // Make API call to logout endpoint
        fetchWrapper.post('/auth/logout', {})
            .then(response => {
                if (response.success) {
                    // Show success message
                    toast.success(response.message || 'Logout successfull.');
                }
            })
            .catch(error => {
                console.log(error);
                toast.error(error.message || error || 'Something went wrong while logging out.');
            }).finally(() => {
                // Remove from localStorage
                localStorage.removeItem('__userInfo');

                // Remove from redux
                dispatch(logout());

                // Remove form data
                dispatch(setFormData({}));

                // Disable loader
                dispatch(setIsLoading(false));

                // Redirect to login
                navigate('/login');
            });
    };

    return { doLogin, doLogout, setupForSilentRefresh };
};