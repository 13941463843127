import { useEffect, useState } from "react";
import { Card } from "../card/Card";
import { InputField } from "../forms/inputField/InputField";
import { Button } from "../forms/button/Button";
import { makeAPICall } from "../../helpers/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { setIsLoading } from "../../redux/slices/preLoaderSlice";

export const ResetPassword = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { userInfo } = useSelector(state => state.authReducer);
    const navigate = useNavigate();

    const [userName, setUserName] = useState('');
    const [OTP, setOTP] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [isEmailSent, setIsEmailSent] = useState(false);

    // Handle send OTP
    const handleSendOTP = async (e) => {
        e.preventDefault();

        // Clear form errors
        setErrors({});

        // Checks for firstName
        if (userName.length === 0) {
            setErrors({ firstName: 'Username is a required field.' });
            return;
        }

        // Make API call to create user
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/users/manage/sendResetEmail/${userName}`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            toast.success(response.message);
            setIsEmailSent(true);
        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    // Handle reset password
    const handleResetPassword = async (e) => {
        e.preventDefault();

        // Clear form errors
        setErrors({});

        // Checks for firstName
        if (userName.length === 0) {
            setErrors({ firstName: 'Username is a required field.' });
            return;
        }

        // Check for password
        if (password.length < 8 ||
            password.length > 20
        ) {
            setErrors({ password: 'Please enter a password of length [8-20] characters long.' });
            return;
        }

        // Make API call to create user
        const { response, error } = await makeAPICall({
            type: 'put',
            endpoint: `/users/manage/changePassword`,
            payload: {
                userName,
                password,
                OTP
            },
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            toast.success(response.message);
            navigate('/login');
        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    useEffect(() => {
        if (userInfo && userInfo.accessToken) {
            navigate((state && state.backTo) || '/');
        }
    }, [userInfo]);

    return (
        <Card centered={true} cardHeader='Reset your account password'>
            <form autoComplete="off">
                <InputField
                    type='text'
                    label='Username'
                    name='userName'
                    value={userName}
                    placeholder='Enter your userName'
                    required={true}
                    onChange={(e) => setUserName(e.target.value)}
                    errors={errors}
                />
                {isEmailSent ? <>
                    <InputField
                        type='number'
                        label='OTP'
                        name='OTP'
                        value={OTP}
                        placeholder='Enter OTP'
                        required={true}
                        onChange={(e) => setOTP(e.target.value)}
                        errors={errors}
                    />

                    <InputField
                        type='password'
                        label='New Password'
                        name='password'
                        value={password}
                        placeholder='Enter your new password'
                        required={true}
                        onChange={(e) => setPassword(e.target.value)}
                        errors={errors}
                    />

                    <Button
                        type='primary'
                        text='Reset Password'
                        onClick={handleResetPassword}
                    />
                </> : <Button
                    type='primary'
                    text='Send OTP'
                    onClick={handleSendOTP}
                />}
            </form>
        </Card>
    );
};