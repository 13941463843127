import styles from './searchableSelectBox.module.css';
import { InputField } from '../inputField/InputField';
import { useEffect, useState } from 'react';
import { getDownIcon, getSmallDownIcon, getSmallUpIcon } from '../../../helpers/iconUtils';

export const SearchableSelectBox = ({ options, onChange, value, setValue, ...extraProps }) => {
    // const [value, setValue] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);

    // Handle focus
    const handleFocus = (e) => {
        e.preventDefault();
        setIsDropdownVisible(true);
    };

    // Handle blur
    const handleBlur = (e) => {
        e.preventDefault();
        setIsDropdownVisible(false);
        
        // Call parent handler
        onChange(value);

        setSelectOptions(options);
    };

    // Handle onChange
    const handleOnChange = (e) => {
        e.preventDefault();
        const inputValue = e.target.value;
        setValue(inputValue);
        setSelectOptions(options.
            filter(option => option.value.toLowerCase().includes(inputValue.toLowerCase())));
    };

    // Handle mouseDown
    const handleMouseDown = (e, option) => {
        e.preventDefault();
        setValue(option.value);
        setIsDropdownVisible(false);
        document.activeElement.blur();

        // Call parent handler
        onChange(option.value);
    };

    useEffect(() => {
        setSelectOptions(options);
    }, [options]);

    return (
        <div className={styles.searchable_container}>
            <InputField
                className={styles.input}
                placeholder={'Search item'}
                value={value}
                onChange={handleOnChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                autoComplete={'none'}
                {...extraProps}
            />

            {/* Caret icons */}
            {isDropdownVisible ? getSmallUpIcon() : getSmallDownIcon()}

            {/* Dropdown list */}
            {isDropdownVisible && <ul className={styles.dropdown_list}>
                {selectOptions.map((option) => {
                    return option.value !== '' && <li key={option.id} onMouseDown={(e) => handleMouseDown(e, option)}>
                        {option.value}
                    </li>
                })}
            </ul>}
        </div>
    );
};