import { useSelector } from 'react-redux';
import styles from './footer.module.css';
import { NavLink } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { getHomeIcon, getOrderedListIcon, getReceiptIcon, getRoundedUserIcon, getShopIcon } from '../../helpers/iconUtils';

export const Footer = () => {
    const { isLoading } = useSelector(state => state.preLoaderReducer);
    return (
        <>
            {/* Taoster */}
            <div><Toaster position='top-right' /></div>

            {/* Preloader */}
            {isLoading && <div className={styles.preloader}></div>}

            <div className={styles.main_footer}>
                <div className={`separator ${styles.separator}`}></div>
                {/* Menu items */}
                <ul className={styles.footer_menu_items}>
                    <li>
                        <NavLink to='/' className={({ isActive, isPending }) => isActive ? styles.active : ''}>
                            {getHomeIcon()}
                            <label>Home</label>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/profile' className={({ isActive, isPending }) => isActive ? styles.active : ''}>
                            {getRoundedUserIcon()}
                            <label>Profile</label>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/my-business' className={({ isActive, isPending }) => isActive ? styles.active : ''}>
                            {getShopIcon()}
                            <label>Business</label>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/my-items' className={({ isActive, isPending }) => isActive ? styles.active : ''}>
                            {getOrderedListIcon()}
                            <label>Items</label>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/create-bills' className={({ isActive, isPending }) => isActive ? styles.active : ''}>
                            {getReceiptIcon()}
                            <label>Create Bills</label>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
};