import { Fragment } from 'react';
import styles from './receipt.module.css';

export const Receipt = ({ data }) => {
    const { businessDetails, customerDetails, billDate, items, subTotal, total, billId } = data;
    return (
        <div className={styles.receipt_container} id='receipt' style={{ width: businessDetails.paperSize || '72mm' }}>
            {/* Business details */}
            <div className={styles.business_details}>
                <p className={styles.business_name}>{businessDetails.name}</p>
                <p className={styles.business_address}>{businessDetails.address}</p>
                <p className={styles.business_phone}>Phone: {businessDetails.phone}</p>
                {businessDetails.gstNo && <p className={styles.business_gst_no}>GSTIN: {businessDetails.gstNo}</p>}
                <p className={styles.invoice_heading}>Retail Invoice</p>
            </div>

            {/* Customer details */}
            <div className={styles.customer_details}>
                <p className={styles.customer_name}>Bill to: {customerDetails.name === '' ? '__________' : customerDetails.name}</p>
                <p className={styles.customer_phone}>Phone: {customerDetails.phone === '' ? 'xxxxxxxxxx' : customerDetails.phone}</p>
            </div>

            {/* Bill details */}
            <div className={styles.bill_details}>
                <p className={styles.bill_no}>Bill No: {billId ? billId : ''}</p>
                <p className={styles.bill_date}>Date: {billDate}</p>
            </div>

            {/* Bill breakup */}
            <div className={styles.bill_breakup}>
                <table className={styles.table}>
                    <tr className={styles.table_heading}>
                        <th>Item</th>
                        <th>Qty</th>
                        <th>Rate</th>
                        <th>Amt</th>
                    </tr>
                    {items.map((item) => {
                        return <tr key={item.id}>
                            <td>{item.name}</td>
                            <td className={styles.qty}>{item.quantity} {item.unit}</td>
                            <td className={styles.rate}>{parseFloat(item.rate).toFixed(2)}</td>
                            <td className={styles.amt}>{parseFloat(item.price).toFixed(2)}</td>
                        </tr>
                    })}
                    <tr className={styles.sub_total}>
                        <td colSpan={3}>Sub Total</td>
                        <td className={styles.subtotal_amt}>{parseFloat(subTotal).toFixed(2)}</td>
                    </tr>

                    {/* Individual GST */}
                    {(businessDetails.gstNo && !businessDetails.cgst && !businessDetails.sgst) && items.map((item, index) => {
                        return (<Fragment key={`${index}_tax`}>
                            {item.cgst && item.sgst && <tr className={styles.taxes}>
                                <td colSpan={3}>CGST @ {item.cgst}% on {parseFloat(item.price).toFixed(2)}</td>
                                <td className={styles.tax_amt}>{(item.cgst / 100 * item.price).toFixed(2)}</td>
                            </tr>}
                            {item.cgst && item.sgst && <tr className={styles.taxes}>
                                <td colSpan={3}>SGST @ {item.sgst}% on {parseFloat(item.price).toFixed(2)}</td>
                                <td className={styles.tax_amt}>{(item.sgst / 100 * item.price).toFixed(2)}</td>
                            </tr>}
                        </Fragment>);
                    })}

                    {/* Overall GST */}
                    {(items.length > 0 && businessDetails.gstNo && businessDetails.cgst && businessDetails.sgst) && (<Fragment>
                        <tr className={styles.taxes}>
                            <td colSpan={3}>CGST @ {businessDetails.cgst}% on {parseFloat(subTotal).toFixed(2)}</td>
                            <td className={styles.tax_amt}>{(businessDetails.cgst / 100 * subTotal).toFixed(2)}</td>
                        </tr>
                        <tr className={styles.taxes}>
                            <td colSpan={3}>SGST @ {businessDetails.sgst}% on {parseFloat(subTotal).toFixed(2)}</td>
                            <td className={styles.tax_amt}>{(businessDetails.sgst / 100 * subTotal).toFixed(2)}</td>
                        </tr>
                    </Fragment>
                    )}
                    <tr className={styles.total}>
                        <td colSpan={3}>Grand Total</td>
                        <td>{Math.round(total).toFixed(2)}</td>
                    </tr>
                </table>
            </div>
            <p className={styles.thanks_notes}><i>E & O.E</i><br />Thanks for your purchase.</p>
        </div>
    );
};