import { speak } from '../../../helpers/utils';
import styles from './inputField.module.css';

export const InputField = ({ label, name, type, value, placeholder, onChange, required, errors, ...extraProps }) => {
    const classes = type === 'checkbox' ? `${styles.form_group} ${styles.checkbox}` : styles.form_group;
    return (
        <div className={classes}>
            {label && <label htmlFor='input-field'>{!!required ? label : `${label} (Optional)`}</label>}
            <input
                type={type}
                value={value}
                name={name}
                placeholder={placeholder}
                required={!!required}
                onChange={onChange}
                onFocus={() => speak(placeholder || label || 'Please fill this field.')}
                {...extraProps}
            />
            {errors && errors[name] && <span className={styles.error}>{errors[name]}</span>}
        </div>
    );
};