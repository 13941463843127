import styles from './aboutUs.module.css';
import { Card } from "../card/Card";
import { Link } from 'react-router-dom';
import { BASE_URL, SUPPORT_MAIL } from '../../helpers/constants';

export const AboutUs = () => {
    return (
        <Card centered={true} classes={[styles.about_card]} cardHeader={<h1>About Us</h1>}>
            <div>
                <p>Welcome to BillGuru, your trusted partner for all your billing and invoicing needs. We are dedicated to simplifying your financial operations with our user-friendly billing software, designed to empower businesses of all sizes.</p>
                <p>At BillGuru, we believe that managing your finances should be hassle-free, efficient, and productive. Our mission is to provide you with the tools and resources you need to streamline your billing processes, enabling you to focus on what truly matters – the growth and success of your business.</p>
            </div>
            <div>
                <p><strong>Who We Are</strong></p>
                <p>BillGuru is a team of passionate individuals with a deep understanding of the challenges faced by businesses when it comes to billing, invoicing, and financial management. Our diverse team, with a blend of technical expertise and industry experience, is committed to developing innovative solutions that make your life easier.</p>
            </div>
            <div>
                <p><strong>Our Commitment</strong></p>
                <p>We are committed to delivering the highest quality billing software that caters to your specific needs. BillGuru is more than just software; it&#8217;s a partner in your success. Here&#8217;s what you can expect from us:</p>
                <ul>
                    <li><strong>Simplicity</strong>: Our software is designed with a user-friendly interface, ensuring that you can start using it without any steep learning curve.</li>
                    <li><strong>Customization</strong>: We understand that every business is unique. That&#8217;s why BillGuru allows you to tailor your invoices and billing processes to suit your brand and preferences.</li>
                    <li><strong>Security</strong>: Your data&#8217;s security is our top priority. BillGuru employs state-of-the-art security measures to protect your sensitive financial information.</li>
                    <li><strong>Support</strong>: Our dedicated support team is always here to assist you. Have a question or encounter an issue? Feel free to reach out to us at <Link to={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</Link>.</li>
                </ul>
            </div>
            <div>
                <p><strong>Why Choose BillGuru</strong></p>
                <ol>
                    <li><strong>Affordability</strong>: We offer cost-effective solutions that won&#8217;t break your budget, whether you&#8217;re a small business or a large enterprise.</li>
                    <li><strong>Ease of Use</strong>: Our software is designed to be intuitive, ensuring that you can start using it immediately.</li>
                    <li><strong>Versatility</strong>: BillGuru can adapt to the unique requirements of your business, whether you&#8217;re in retail, services, or any other industry.</li>
                    <li><strong>Time-saving</strong>: Spend less time on billing and more time on what you love to do &#8211; growing your business.</li>
                </ol>
            </div>
            <div>
                <p><strong>Get Started with BillGuru</strong></p>
                <p>Ready to take control of your billing and invoicing processes? Join the BillGuru family and experience the difference. Our team is excited to support you on your journey towards financial efficiency.</p>
                <p>Visit our website at <Link to={BASE_URL} target="_blank" rel="noopener">billguru.in</Link> to explore our software and learn more about how BillGuru can benefit your business. Feel free to reach out to us at <Link to={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</Link> with any inquiries or to request a demo.</p>
                <p>Thank you for choosing BillGuru as your billing software solution. We look forward to serving you and helping your business thrive.</p>
            </div>
        </Card>
    );
};