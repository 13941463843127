import styles from './layout.module.css';
import { Outlet } from 'react-router-dom';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';

export const Layout = () => {
    return (
        // Main container
        <div className={styles.main_container}>
            {/* Header */}
            <Header />

            {/* Main contents */}
            <div className={styles.main_contents}>
                {/* Inject nested components */}
                <Outlet />
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
};