import styles from './card.module.css';

export const Card = ({ children, fullWidth, centered, cardHeader, classes = [] }) => {
    let className = styles.card;
    className += !!fullWidth ? ` ${styles.full_width}` : ``;
    className += !!centered ? ` ${styles.centered}` : ``;
    className += classes.length ? ` ${classes.join(' ')}` : ``;
    return (
        <div className={className}>
            {
                cardHeader && <>
                    <div className={styles.card_header}>
                        {cardHeader}
                    </div>
                    <div className={`separator ${styles.separator}`}></div>
                </>
            }
            {children}
        </div>
    );
};