import styles from './generateReceipt.module.css';
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { setIsLoading } from "../../redux/slices/preLoaderSlice";
import { clearFormData, setFormData } from '../../redux/slices/formSlice';
import html2canvas from 'html2canvas';
import { makeAPICall } from "../../helpers/utils";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { Card } from '../card/Card';
import { getDownloadIcon, getEyeIcon, getPlusIcon, getPrintIcon, getRoundedCheckIcon, getShareIcon, getWhatsappIcon, getXIcon } from '../../helpers/iconUtils';
import { InputField } from '../forms/inputField/InputField';
import { Button } from '../forms/button/Button';
import { useEffect, useRef, useState } from 'react';
import { isValidIndianMobileNumber } from '../../helpers/utils';
import { SearchableSelectBox } from '../forms/searchableSelectBox/SearchableSelectBox';
import { Receipt } from '../receipt/Receipt';
import { cloneDeep } from 'lodash';
import { BASE_URL, SITE_NAME, SITE_TAGLINE } from '../../helpers/constants';

export const GenerateReceipt = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { formData } = useSelector(state => state.formReducer);

    // Business
    const [businessData, setBusinessData] = useState({
        businessDetails: {},
        items: [],
        itemOptions: [{ id: 0, key: '', value: '' }],
        receiptData: {}
    });

    // Customer data
    const [customerName, setCustomerName] = useState('');
    const [customerMobileNumber, setCustomerMobileNumber] = useState('');

    // Table data
    const firstRowDefaultValue = {
        name: '', quantity: 1, unit: 'N/A', rate: 'N/A', price: 'N/A'
    }
    const [rowData, setRowData] = useState([firstRowDefaultValue]);
    const [selectedOption, setSelectedOption] = useState(businessData.itemOptions[0].value);

    // Receipt data
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [errors, setErrors] = useState({});
    const receiptAction = useRef('print');

    // Re-render receipt based on below state
    const [isReceiptToBeUpdated, setIsReceiptToBeUpdated] = useState(false);

    // Get business info
    const getBusinessInfo = async () => {
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/business`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            return response.data;
        } else {
            return null;
        }
    };

    // Get items info
    const getItemsInfo = async () => {
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/items`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            return response.data;
        } else {
            return null;
        }
    };

    // Fetch all required data
    const fetchAndSetAllRequiredData = async () => {
        const businessInfo = await getBusinessInfo();
        // Checks
        if (!businessInfo ||
            !businessInfo.name ||
            !businessInfo.address ||
            !businessInfo.phone
        ) {
            navigate('/my-business');
            toast.error('Add business details first in order to create receipt.');
            return;
        }

        const itemInfo = await getItemsInfo();
        // Checks
        if (!itemInfo || itemInfo.length === 0) {
            navigate('/my-items');
            toast.error('Add items first in order to create receipt.');
            return;
        }

        // Response data
        const responseData = {
            businessDetails: businessInfo,
            items: itemInfo
        };

        // Update state variables & redux store
        const data = {
            businessDetails: responseData.businessDetails,
            items: responseData.items,
            itemOptions: [...businessData.itemOptions,
            ...responseData.items.map((item, index) => {
                return {
                    id: ++index,
                    key: item.name,
                    value: item.name
                }
            })],
            receiptData: {
                billDate: new Date().toLocaleString(),
                customerName,
                customerMobileNumber,
                subTotal,
                total,
                rowData: rowData.slice(1)
            }
        };
        setBusinessData(data);
        dispatch(setFormData(data));
    };


    // Set initial data
    useEffect(() => {
        // If first time, make API call
        if (!formData.businessDetails || !formData.items || !formData.itemOptions) {
            fetchAndSetAllRequiredData();
            console.info('Data availability: in-API');
        } else {
            console.info('Data availability: in-store');
            // Update business data
            setBusinessData(cloneDeep({
                ...formData,
                receiptData: {
                    ...formData.receiptData,
                    billDate: new Date().toLocaleString(),
                }
            }));

            // Update table row data
            if (rowData.length === 1) {
                setRowData([...rowData, ...formData?.receiptData?.rowData]);
            }

            // Update other data
            setCustomerName(formData?.receiptData?.customerName || '');
            setCustomerMobileNumber(formData?.receiptData?.customerMobileNumber || '');
        }
    }, [formData]);

    // Update dependent data when row data changes
    useEffect(() => {
        let _subTotal = 0;
        let _total = 0;
        rowData.slice(1).forEach((item) => {
            _subTotal += parseFloat(item.price);
            if ((businessData?.businessDetails?.gstNo) &&
                (!businessData?.businessDetails?.cgst && !businessData?.businessDetails?.sgst) &&
                (item.cgst && item.sgst)
            ) {
                _total += item.cgst ? (item.cgst / 100) * item.price : 0;
                _total += item.sgst ? (item.sgst / 100) * item.price : 0;
            }
        });

        _total += _subTotal;

        if ((businessData?.businessDetails?.gstNo) &&
            (businessData?.businessDetails?.cgst && businessData?.businessDetails?.sgst)
        ) {
            _total += ((parseFloat(businessData?.businessDetails?.cgst) / 100) * _total) +
                ((parseFloat(businessData?.businessDetails?.cgst) / 100) * _total);
        }

        setSubTotal(_subTotal);
        setTotal(_total);
    }, [rowData]);

    // Check and set customer name
    const checkAndSetCustomerName = (value) => {
        // Set customer name
        setCustomerName(value);

        // Update redux store
        dispatch(setFormData({
            ...formData,
            receiptData: {
                ...formData.receiptData,
                customerName: value,
                isReceiptSaved: false
            }
        }));
    };

    // Check and set customer mobile number
    const checkAndSetCustomerMobileNumber = (value) => {
        // Check for valid length
        if (value.length > 10) {
            return;
        }

        // Set mobile number
        setCustomerMobileNumber(value);

        // Check for valid mobile number
        if (isValidIndianMobileNumber(value) || value == '0000000000') {
            setErrors({ ...errors, customerMobileNumber: null });

            // Update redux store
            dispatch(setFormData({
                ...formData,
                receiptData: {
                    ...formData.receiptData,
                    customerMobileNumber: value,
                    isReceiptSaved: false
                }
            }));
        } else {
            setErrors({ ...errors, customerMobileNumber: 'Invalid mobile number.' });
        }
    };

    // On selection of item
    const onItemSelect = (value) => {
        // Clear errors
        setErrors({});

        const tempRowData = [...rowData];
        const _selectedValue = businessData.itemOptions.find((item) => item.value === value)?.value ||
            businessData.itemOptions[0].value;
        setSelectedOption(_selectedValue);
        if (_selectedValue === (businessData?.items[0]?.value || '')) {
            // Reset first row & return
            tempRowData[0] = firstRowDefaultValue;
            setRowData(tempRowData);
            return;
        }
        const selectedItem = businessData.items.find((item) => item.name === _selectedValue);
        if (!selectedItem) {
            // Reset first row & return
            tempRowData[0] = firstRowDefaultValue;
            setRowData(tempRowData);
            return;
        }
        tempRowData[0] = {
            ...selectedItem,
            quantity: tempRowData[0].quantity,
            price: tempRowData[0].quantity * selectedItem.rate
        };
        setRowData(tempRowData);
    };

    // On quantity change
    const onQuantityChange = (value) => {
        // Clear errors
        setErrors({});

        // Checks
        if (rowData[0].name === '') {
            return;
        }

        const tempRowData = [...rowData];
        tempRowData[0] = {
            ...tempRowData[0],
            quantity: value,
            price: value * tempRowData[0].rate
        };
        setRowData(tempRowData);
    };

    // Handle add
    const handleAdd = (e) => {
        e.preventDefault();

        // Clear errors
        setErrors({});

        // Checks
        if (rowData[0].name === '') {
            setErrors({ item_name: 'Invalid item.' });
            return;
        } else if (rowData[0].quantity <= 0) {
            setErrors({ quantity: 'Invalid quantity.' });
            return;
        } else if (
            rowData[0].unit === 'N/A' ||
            rowData[0].rate === 'N/A' ||
            rowData[0].price === 'N/A'
        ) {
            toast.error('Some data is missing, please reload the page.');
            return;
        }

        // Copy rows data
        const tempRowData = [...rowData];

        // Check if item already added
        const alreadyAddedItemIndex = tempRowData.slice(1).
            findIndex((item) => item.name === tempRowData[0].name);
        if (alreadyAddedItemIndex !== -1) {
            tempRowData.splice(alreadyAddedItemIndex + 1, 1);
        }

        // Add data to 2nd index
        tempRowData.splice(1, 0, tempRowData[0]);

        // Reset selectbox value
        setSelectedOption(businessData.itemOptions[0].value);

        // Reset first row data
        tempRowData[0] = firstRowDefaultValue;

        // Update the rowData with newly created data
        setRowData(tempRowData);

        // Update redux store
        dispatch(setFormData({
            ...formData,
            receiptData: {
                ...formData.receiptData,
                rowData: tempRowData.slice(1),
                isReceiptSaved: false
            }
        }));
    };

    // Handle delete
    const handleDelete = (e, index) => {
        e.preventDefault();

        // Copy rows data
        const tempRowData = [...rowData];

        // Delete item
        tempRowData.splice(index, 1);

        // Update final list after delete
        setRowData(tempRowData);

        // Update redux store
        dispatch(setFormData({
            ...formData,
            receiptData: {
                ...formData.receiptData,
                rowData: tempRowData.slice(1),
                isReceiptSaved: false
            }
        }));
    };

    // Reset current receipt data
    const resetCurrentReceiptData = () => {
        // if (!window.confirm('Are you sure, you want to clear current receipt data ?')) {
        //     return;
        // }
        setCustomerName('');
        setCustomerMobileNumber('');
        setRowData([firstRowDefaultValue]);

        // Update redux store
        dispatch(clearFormData());
    };

    // Get image
    const getImage = async (element) => {
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL('image/png', 1.0);
        return image;
    };

    // Save receipt
    const saveReceipt = async () => {
        if (formData?.receiptData?.isReceiptSaved) {
            // Re-render updated receipt
            setIsReceiptToBeUpdated(true);
            return true;
        }
        let requestType = 'post';
        if (formData?.receiptData?.mode && formData?.receiptData?.mode === 'update') {
            requestType = 'put';
        }
        const receiptData = {
            businessDetails: businessData.businessDetails,
            customerDetails: {
                name: customerName,
                phone: customerMobileNumber
            },
            billDate: businessData?.receiptData?.billDate,
            items: rowData.slice(1),
            subTotal,
            total,
            ...(formData?.receiptData?.mode &&
                formData?.receiptData?.mode === 'update' &&
                { billId: formData?.receiptData?.billNo })
        };

        const { response, error } = await makeAPICall({
            type: requestType,
            endpoint: `/receipts`,
            payload: { receiptData },
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            // Update redux store
            dispatch(setFormData({
                ...formData,
                receiptData: {
                    ...formData.receiptData,
                    isReceiptSaved: true,
                    ...((!formData?.receiptData?.mode ||
                        formData?.receiptData?.mode !== 'update') &&
                    {
                        billNo: response?.data?.billNo,
                        billId: response?.data?.billId,
                        mode: 'update'
                    })
                }
            }));
            setIsReceiptToBeUpdated(true);
            return true;
        } else {
            // Re-render updated receipt
            setIsReceiptToBeUpdated(true);
            return false;
        }
    };

    // Checks before print, share or download
    const isAllowedToPrintShareOrDownload = () => {
        // Checks
        if (!isValidIndianMobileNumber(customerMobileNumber) && customerMobileNumber !== '') {
            toast.error('Either enter correct mobile number or leave it blank.');
            return false;
        } else if (rowData.length < 2) {
            toast.error('Please add some items in order to proceed further.');
            return false;
        }
        return true;
    };

    // Handle print
    const handlePrint = async () => {
        document.title = `${SITE_NAME}-receipt-${new Date().getTime()}`;
        window.print();
        document.title = `${SITE_NAME} - ${SITE_TAGLINE}`;
    };

    // Handle share
    const handleShare = async () => {
        try {
            const element = document.querySelector('#receipt');
            element.style.padding = '0.5rem';
            const image = await getImage(element);
            element.style.padding = '0';

            // Convert dataURL to blob
            const res = await fetch(image);
            const blob = await res.blob();
            const file = new File(
                [blob],
                `${SITE_NAME}-receipt-${new Date().getTime()}.png`,
                {
                    type: 'image/png',
                    lastModified: new Date()
                }
            );

            // Checks
            if (!navigator.share || !navigator.canShare) {
                handleReceiptAction('download');
                return;
            }

            // Try sharing
            await navigator.share({
                title: 'Share Bill',
                files: [file]
            });
        } catch (err) {
            handleReceiptAction('download');
        }
    };

    // Handle download
    const handleDownload = async () => {
        try {
            const element = document.querySelector('#receipt');
            element.style.padding = '0.5rem';
            const image = await getImage(element);
            element.style.padding = '0';
            const downloadLink = window.document.createElement('a');
            downloadLink.style.display = 'none';
            downloadLink.download = `${SITE_NAME}-receipt-${new Date().getTime()}.png`;
            downloadLink.href = image;
            // downloadLink.href = `whatsapp://send?phone=91xxxxxxxxxx&text=${image}`;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            downloadLink.remove();
        } catch (err) {
            toast.error('Something went wrong.');
        }
    };

    // Handle Whatsapp share
    const handleWhatsappShare = () => {
        if (!isValidIndianMobileNumber(customerMobileNumber)) {
            toast.error('Please enter a valid mobile number in order to share on Whatsapp.');
            return;
        } else if (formData && formData.receiptData && !formData.receiptData.billNo) {
            toast.error('Whatsapp share is disabled since you have turned off the feature to save receipts.');
            return;
        }

        const billLink = `${BASE_URL}/view-receipt/${formData?.receiptData?.billNo}`;
        const whatsappLink = ` https://wa.me/91${customerMobileNumber}?text=${encodeURIComponent(billLink)}`;

        const link = window.document.createElement('a');
        link.style.display = 'none';
        link.target = '_blank';
        link.href = whatsappLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
    };

    // Save receipt & set receiptAction
    const handleReceiptAction = async (action) => {
        // Checks
        if (!isAllowedToPrintShareOrDownload()) {
            return;
        }

        // Update receiptAction
        receiptAction.current = action;

        // Save receipt if saving is allowed by the user
        if (businessData?.businessDetails?.saveReceipts === 1) {
            await saveReceipt();
        } else {
            // Re-render updated receipt
            setIsReceiptToBeUpdated(true);
        }
    };

    useEffect(() => {
        if (isReceiptToBeUpdated) {
            if (receiptAction.current === 'print') {
                handlePrint()
            } else if (receiptAction.current === 'share') {
                handleShare();
            } else if (receiptAction.current === 'whatsapp_share') {
                handleWhatsappShare();
            } else {
                handleDownload();
            }
            setIsReceiptToBeUpdated(false);
        }
    }, [isReceiptToBeUpdated]);

    return (
        // Details container
        <div className={styles.details_container}>
            {/* Details */}
            <Card
                fullWidth={true}
                classes={[styles.print_details_container]}
                cardHeader={
                    <div className={styles.card_title}>
                        {getRoundedCheckIcon()}
                        <span>Bill Details</span>
                        <Button
                            type={'danger'}
                            text={<>
                                {getPlusIcon()}
                                <span>New Bill</span>
                            </>}
                            onClick={resetCurrentReceiptData}
                        />
                    </div>
                }
            >
                {/* Receipt action details */}
                {/* <div className={styles.receipt_actions}>
                    <InputField
                        type={'radio'}
                        label={'Print'}
                        required={true}
                        name={'receipt_action'}
                        value={'print'}
                        checked={receiptAction === 'print'}
                        onChange={() => setReceiptAction('print')}
                    />

                    <InputField
                        type={'radio'}
                        label={'Whatsapp'}
                        required={true}
                        name={'receipt_action'}
                        value={'whatsapp'}
                        checked={receiptAction === 'whatsapp'}
                        onChange={() => setReceiptAction('whatsapp')}
                    />

                    <InputField
                        type={'radio'}
                        label={'Print & Whatsapp'}
                        required={true}
                        name={'receipt_action'}
                        value={'print_whatsapp'}
                        checked={receiptAction === 'print_whatsapp'}
                        onChange={() => setReceiptAction('print_whatsapp')}
                    />

                    <Button
                        type={'success'}
                        text={receiptAction === 'print' ?
                            'Print' : receiptAction === 'whatsapp' ?
                                'Whatsapp' : 'Print & Whatsapp'}
                        onClick={handleReceiptAction}
                    />
                </div> */}

                {/* Separator */}
                <div className='separator'></div>

                {/* Customer details */}
                <div className={styles.customer_details}>
                    <InputField
                        type={'text'}
                        label={'Customer Name'}
                        placeholder={'Enter customer name'}
                        required={false}
                        name={'customerName'}
                        value={customerName}
                        onChange={(e) => checkAndSetCustomerName(e.target.value)}
                        errors={errors}
                        autoComplete={'none'}
                    />

                    <InputField
                        type={'number'}
                        label={'Mobile Number'}
                        placeholder={'Enter customer mobile number'}
                        required={false}
                        name={'customerMobileNumber'}
                        value={customerMobileNumber}
                        onChange={(e) => checkAndSetCustomerMobileNumber(e.target.value)}
                        errors={errors}
                        autoComplete={'none'}
                    />
                </div>

                {/* Item details */}
                <div className={styles.item_details}>
                    <table className={styles.table}>
                        <tr>
                            <th>Item</th>
                            <th>Qty</th>
                            {/* <th>Unit</th> */}
                            <th>Rate</th>
                            <th>Price</th>
                            <th>Action</th>
                        </tr>
                        {rowData.map((item, index) => {
                            return (
                                <tr className={styles.row} key={index}>
                                    <td className={styles.item_name}>
                                        {index === 0 ?
                                            <SearchableSelectBox
                                                name={'item_name'}
                                                options={businessData.itemOptions}
                                                value={selectedOption}
                                                setValue={setSelectedOption}
                                                onChange={(value) => onItemSelect(value)}
                                                required={true}
                                                errors={errors}
                                            /> :
                                            item.name}
                                    </td>
                                    <td className={styles.qty}>
                                        {index === 0 ?
                                            <InputField
                                                type={'number'}
                                                placeholder={'Enter quantity'}
                                                required={true}
                                                name={'quantity'}
                                                value={item.quantity}
                                                onChange={(e) => onQuantityChange(e.target.value)}
                                                errors={errors}
                                                autoComplete={'none'}
                                                disabled={!item.name}
                                            /> :
                                            <>{item.quantity} {item.unit}</>}
                                    </td>
                                    {/* <td className={styles.unit}>{item.unit}</td> */}
                                    <td>{item.rate}</td>
                                    <td>{item.price}</td>
                                    <td className={styles.action_buttons}>
                                        {index === 0 && <Button
                                            type={'success'}
                                            text={getPlusIcon()}
                                            rounded={true}
                                            title={'Add Item'}
                                            onClick={(e) => handleAdd(e)}
                                        />}
                                        {index > 0 && <Button
                                            type={'danger'}
                                            text={getXIcon()}
                                            rounded={true}
                                            title={'Remove Item'}
                                            onClick={(e) => handleDelete(e, index)}
                                        />}
                                    </td>
                                </tr>
                            );
                        })}

                    </table>
                </div>
            </Card>

            {/* Preview */}
            <Card
                fullWidth={true}
                classes={[styles.print_preview_container]}
                cardHeader={
                    <div className={styles.card_title}>
                        {getEyeIcon()}
                        <span>Live Preview</span>
                    </div>
                }
            >
                <div className={styles.receipt_preview}>
                    {/* Receipt actions */}
                    <div className={styles.receipt_actions}>
                        <Button
                            type={'primary'}
                            text={getPrintIcon()}
                            onClick={() => handleReceiptAction('print')}
                            rounded={true}
                            title={'Print this receipt'}
                        />
                        <Button
                            type={'success'}
                            text={getWhatsappIcon()}
                            onClick={() => handleReceiptAction('whatsapp_share')}
                            rounded={true}
                            title={'Share on WhatsApp'}
                        />
                        <Button
                            type={'danger'}
                            text={getDownloadIcon()}
                            onClick={() => handleReceiptAction('download')}
                            rounded={true}
                            title={'Download this bill'}
                        />
                        <Button
                            type={'primary'}
                            text={getShareIcon()}
                            onClick={() => handleReceiptAction('share')}
                            rounded={true}
                            title={'Share this receipt'}
                        />
                    </div>

                    {/* Separator */}
                    <div className={`separator ${styles.mb1}`}></div>

                    {/* Receipt */}
                    <Receipt data={{
                        businessDetails: businessData.businessDetails,
                        customerDetails: {
                            name: customerName,
                            phone: customerMobileNumber
                        },
                        billDate: businessData?.receiptData?.billDate,
                        items: rowData.slice(1),
                        subTotal,
                        total,
                        billId: businessData?.receiptData?.billId
                    }}
                    />
                </div>
            </Card>
        </div>
    );
};