import styles from './faq.module.css';
import { Card } from "../card/Card";
import { SUPPORT_MAIL } from '../../helpers/constants';
import { Link } from 'react-router-dom';

export const FAQ = () => {
    return (
        <Card centered={true} classes={[styles.faq_card]} cardHeader={<h1>FAQ - Frequently Asked Questions</h1>}>
            <div>
                <p>Welcome to our FAQ page, where we address some of the most common questions about BillGuru, our billing software, and our services. If you have a question that&#8217;s not covered here, feel free to contact us at <Link to={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</Link>, and we&#8217;ll be happy to assist you.</p>
            </div>
            <div>
                <p><strong>1. What is BillGuru?</strong></p>
                <p>BillGuru is a comprehensive billing and invoicing software designed to streamline financial processes for businesses of all sizes. It helps you create professional invoices, manage payments, and track your finances efficiently.</p>
            </div>
            <div>
                <p><strong>2. How can I get started with BillGuru?</strong></p>
                <p>To get started with BillGuru, simply visit our website at www.billguru.in and sign up for an account. You can choose from our different pricing plans, including a free trial option, to start using our software.</p>
            </div>
            <div>
                <p><strong>3. Is there a free trial available?</strong></p>
                <p>Yes, we offer a free trial of BillGuru so that you can experience our software before making a commitment. Sign up for the trial to explore our features and see how it can benefit your business.</p>
            </div>
            <div>
                <p><strong>4. What types of businesses can benefit from BillGuru?</strong></p>
                <p>BillGuru is suitable for a wide range of businesses, including freelancers, small and medium-sized enterprises, and large corporations. Whether you&#8217;re in retail, services, or any other industry, our software can be tailored to your specific needs.</p>
            </div>
            <div>
                <p><strong>5. Is my data safe with BillGuru?</strong></p>
                <p>Absolutely. We take data security seriously and employ industry-standard security measures to protect your information. Your data is encrypted, and we ensure the confidentiality and integrity of your sensitive information.</p>
            </div>
            <div>
                <p><strong>6. How can I contact BillGuru&#8217;s customer support?</strong></p>
                <p>You can reach our customer support team by emailing <Link to={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</Link>. We&#8217;re here to assist you with any technical issues, questions, or concerns you may have.</p>
            </div>
            <div>
                <p><strong>7. Can I customize my invoices with BillGuru?</strong></p>
                <p>Yes, BillGuru allows you to customize your invoices to match your brand&#8217;s identity. You can add your company name, phone number, and address and personalize the layout to create professional, branded invoices.</p>
            </div>
            <div>
                <p><strong>8. Can I use BillGuru on mobile devices?</strong></p>
                <p>Yes, BillGuru is accessible on various devices, including mobile phones and tablets. We offer a responsive design to ensure you can use our software on the go.</p>
            </div>
            <div>
                <p><strong>9. What if I have specific requirements or need additional features?</strong></p>
                <p>If you have unique requirements or need specific features, please reach out to our support team. We are open to customization and can discuss potential solutions to meet your needs.</p>
            </div>
        </Card>
    );
};