import styles from './reports.module.css';
import { useState } from 'react';
import toast from "react-hot-toast";
import { Card } from '../card/Card';
import { InputField } from '../forms/inputField/InputField';
import { getISOFormattedDateTime, makeAPICall } from '../../helpers/utils';
import { Button } from '../forms/button/Button';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/slices/preLoaderSlice';

export const Reports = () => {
    const midnight = new Date(new Date().setHours(0, 0, 0, 0));
    const [startDate, setStartDate] = useState(getISOFormattedDateTime(midnight));
    const [endDate, setEndDate] = useState(getISOFormattedDateTime(new Date()));

    const dispatch = useDispatch();
    const [report, setReport] = useState(null);

    const handleApply = async () => {
        const startTimestamp = new Date(startDate).getTime();
        const endTimestamp = new Date(endDate).getTime();
        if ((endTimestamp - startTimestamp) < 0) {
            toast.error('Start Datetime should be less than End Datetime.');
            return;
        } else if ((endTimestamp - startTimestamp) > 15778476000) {
            toast.error('Selected datetime range cannot be more than 6 months.');
            return;
        }

        // Make API call to get analytics for selected date range
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/receipts/analytics/${startTimestamp}/${endTimestamp}`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            setReport(response.data);
        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    return (
        <Card centered={true} fullWidth={true} cardHeader={<h1>Reports</h1>}>
            <form autoComplete='off' className={styles.filter_form}>
                <InputField
                    type='datetime-local'
                    label='Start Datetime'
                    name='startDate'
                    value={startDate}
                    placeholder='Enter start datetime'
                    required={true}
                    onChange={(e) => setStartDate(e.target.value)}
                    errors={[{}]}
                />
                <InputField
                    type='datetime-local'
                    label='End Datetime'
                    name='endDate'
                    value={endDate}
                    placeholder='Enter end datetime'
                    required={true}
                    onChange={(e) => setEndDate(e.target.value)}
                    errors={[{}]}
                />
                <Button
                    type='primary'
                    text='Apply'
                    onClick={handleApply}
                />
            </form>

            {report && <>
                {/* Separator */}
                <div className='separator'></div>

                {/* Total number of receipts */}
                <div className={styles.report_flex}>
                    <div className={styles.report_box}>
                        <div className={styles.report_title}>Total Bills</div>
                        <div className={styles.report_contents}>
                            {report.totalReceipts}
                        </div>
                    </div>

                    {/* Total Amount without GST */}
                    <div className={styles.report_box}>
                        <div className={styles.report_title}>Total Amount <sup>(without GST)</sup></div>
                        <div className={styles.report_contents}>
                            &#8377;{` ${report.subTotalSum && report.subTotalSum.toFixed(2) || '--'}`}
                        </div>
                    </div>

                    {/* Total Amount with GST */}
                    <div className={styles.report_box}>
                        <div className={styles.report_title}>Total Amount <sup>(with GST)</sup></div>
                        <div className={styles.report_contents}>
                            &#8377;{` ${report.totalSum && report.totalSum.toFixed(2) || '--'}`}
                        </div>
                    </div>
                </div>
            </>}
        </Card>
    );
};