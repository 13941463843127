import { useParams } from "react-router-dom";
import { Card } from "../card/Card";
import { useEffect, useState } from "react";
import { makeAPICall } from '../../helpers/utils';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/slices/preLoaderSlice';
import toast from "react-hot-toast";
import { SUPPORT_MAIL } from "../../helpers/constants";
import { Link } from 'react-router-dom';

export const Payment = () => {
    const dispatch = useDispatch();
    const { status } = useParams();
    const [subscriptionPlanStartsAt, setSubscriptionPlanStartsAt] = useState(null);
    const [subscriptionPlanEndsAt, setSubscriptionPlanEndsAt] = useState(null);

    // Get user info
    const getUserData = async () => {
        // Make API call to get user
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/users/transaction`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            setSubscriptionPlanStartsAt(response.data.subscriptionPlanStartsAt);
            setSubscriptionPlanEndsAt(response.data.subscriptionPlanEndsAt);
        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    useEffect(() => {
        if (status && status !== '') {
            getUserData();
        }
    }, [status]);

    return (
        <Card centered={true} cardHeader={'Payment Info'}>
            <p>Your payment orderId is: <mark>{status}</mark></p><br /><br />
            <p>Your updated subscription plan starts at <mark>{(subscriptionPlanStartsAt && new Date(subscriptionPlanStartsAt).toLocaleString()) || '-'}</mark> and ends at <mark>{(subscriptionPlanEndsAt && new Date(subscriptionPlanEndsAt).toLocaleString()) || '-'}</mark></p><br /><br />
            <p>In case, if updated subscription plan duration is not reflecting correctly, please allow us up to 30 mins and then <Link to={`mailto:${SUPPORT_MAIL}`}>contact us</Link> with the above orderId.</p><br /><br />
            <p>You need to logout on all your devices and then, login again in order to activate your new subscription.</p>
        </Card>
    );
};