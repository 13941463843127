import styles from './button.module.css';

export const Button = ({ type, text, rounded = false, onClick, ...extraProps }) => {
    return (
        <button
            type='button'
            className={`${styles[type]} ${rounded ? styles.rounded : ''}`}
            onClick={onClick}
            {...extraProps}
        >
            {text}
        </button>
    );
};