import styles from './items.module.css';
import { Card } from "../card/Card";
import { InputField } from '../forms/inputField/InputField';
import { Button } from '../forms/button/Button';
import { useEffect, useState } from 'react';
import { getRoundedCheckIcon, getEditIcon, getXIcon } from '../../helpers/iconUtils';
import { makeAPICall } from '../../helpers/utils';
import { useDispatch } from 'react-redux';
import { setIsLoading } from "../../redux/slices/preLoaderSlice";
import toast from "react-hot-toast";
import { setFormData } from '../../redux/slices/formSlice';

export const Items = () => {
    const dispatch = useDispatch();

    // Items card fields
    const [itemName, setItemName] = useState('');
    const [unit, setUnit] = useState('');
    const [rate, setRate] = useState('');
    const [cgst, setCgst] = useState('');
    const [sgst, setSgst] = useState('');
    const [editItemId, setEditItemId] = useState('');
    const [gst, setGst] = useState('');


    // Errors
    const [errors, setErrors] = useState({});

    // Operation
    const [operation, setOperation] = useState('add');

    // Items
    const [items, setItems] = useState([]);

    // Fetch more items
    const [shouldFetchMoreItems, setShouldFetchMoreItems] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    // Get items
    const getItems = async (page = 1) => {
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/items?page=${page}`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {

            // If fetched records are less than 20, disable load more button
            if (response.data.length < 20) {
                setShouldFetchMoreItems(false);
            } else {
                setShouldFetchMoreItems(true);
            }

            // Increase currentPage count
            setCurrentPage(page + 1);

            // If querying for next page, retain existing data
            if (page > 1) {
                setItems([...items, ...response.data]);
            } else {
                setItems(response.data);
            }
        } else {
            toast.error('Something went wrong while fetching items.');
        }
    };

    // Clear item form
    const clearItemForm = () => {
        setOperation('add');
        setItemName('');
        setUnit('');
        setRate('');
        setCgst('');
        setSgst('');
        setGst('');
    };

    // Save item
    const saveItem = async () => {
        // checks
        if (itemName?.length === 0) {
            toast.error('Please enter item name.');
            return;
        } else if (unit?.length < 1 || unit?.length > 5) {
            toast.error('Unit should be [1-5] characters long.');
            return;
        } else if (rate < 0) {
            toast.error('Rate cannot be negative.');
            return;
        } else if (
            (cgst !== '' && parseFloat(cgst) <= 0 || parseFloat(cgst) > 100) ||
            (sgst !== '' && parseFloat(sgst) <= 0 || parseFloat(sgst) > 100)
        ) {
            toast.error('GST value must be between [0-100] or leave it blank.');
            return;
        }

        const { response, error } = await makeAPICall({
            type: operation === 'add' ? 'post' : 'put',
            endpoint: `/items`,
            payload: {
                ...(operation !== 'add') && { id: editItemId },
                name: itemName,
                unit,
                rate,
                cgst,
                sgst
            },
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            // Update redux store
            dispatch(setFormData({}));
            toast.success('Item got saved successfully.');
            getItems();
            clearItemForm();
        } else {
            toast.error(error.message || error || 'Something went wrong while saving item.');
        }
    };

    // Handle edit item
    const handleEditItem = (item) => {
        setOperation('edit');
        setEditItemId(item.id);
        setItemName(item.name);
        setUnit(item.unit);
        setRate(item.rate);
        setCgst(item.cgst);
        setSgst(item.sgst);
        if (item.cgst && item.sgst) {
            setGst(parseFloat(item.cgst) + parseFloat(item.sgst));
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    // Delete item
    const deleteItem = async (id) => {
        const { response, error } = await makeAPICall({
            type: 'delete',
            endpoint: `/items/${id}`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            // Update redux store
            dispatch(setFormData({}));
            toast.success('Item got deleted successfully.');
            getItems();
        } else {
            toast.error(error.message || error || 'Something went wrong while deleting item.');
        }
    };

    // Handle delete item
    const handleDeleteItem = (item) => {
        if (!window.confirm(`Are you sure, you want to delete [${item.name}] ?`)) {
            return;
        }
        deleteItem(item.id);
    };

    useEffect(() => {
        getItems();
    }, []);

    useEffect(() => {
        let half = parseFloat(gst / 2).toFixed(2);
        half = half == 0 ? '' : half;
        setCgst(half);
        setSgst(half);
    }, [gst]);

    return (
        <>
            {/* Items */}
            <Card
                centered={true}
                cardHeader={<div className={styles.card_title}>
                    {getRoundedCheckIcon()}
                    <span>Items</span>
                </div>}>
                <InputField
                    type={'text'}
                    label={'Item Name'}
                    placeholder={'Enter item name'}
                    required={true}
                    name={'itemName'}
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                <InputField
                    type={'text'}
                    label={'Unit (Keep it short)'}
                    placeholder={'Enter item unit (Eg - pc, kg, lt etc)'}
                    required={true}
                    name={'unit'}
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                <InputField
                    type={'number'}
                    label={'Rate per unit'}
                    placeholder={'Enter item rate per unit'}
                    required={true}
                    name={'rate'}
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                <InputField
                    type={'number'}
                    label={'GST (%)'}
                    placeholder={'Enter GST percentage (Eg - 5, 14, 18 etc)'}
                    required={false}
                    name={'gst'}
                    value={gst}
                    onChange={(e) => setGst(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                {/* <InputField
                    type={'number'}
                    label={'CGST (%)'}
                    placeholder={'Enter CGST percentage (Eg - 5, 14, 18 etc)'}
                    required={false}
                    name={'cgst'}
                    value={cgst}
                    onChange={(e) => setCgst(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                /> */}
                {/* <InputField
                    type={'number'}
                    label={'SGST (%)'}
                    placeholder={'Enter SGST percentage (Eg - 5, 14, 18 etc)'}
                    required={false}
                    name={'sgst'}
                    value={sgst}
                    onChange={(e) => setSgst(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                /> */}
                <Button
                    type={'primary'}
                    text={'Save item'}
                    onClick={saveItem}
                />

                {items.length > 0 && <>
                    {/* Separator */}
                    <div className='separator'></div>

                    {/* Items container */}
                    <div className={styles.items_container}>
                        <table className={styles.table}>
                            <tr>
                                <th>Name</th>
                                <th>Unit</th>
                                <th>Rate</th>
                                <th>CGST</th>
                                <th>SGST</th>
                                <th>Actions</th>
                            </tr>
                            {items.map((item) => <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>{item.unit}</td>
                                <td>{item.rate}</td>
                                <td>{item.cgst}</td>
                                <td>{item.sgst}</td>
                                <td>
                                    <div className={styles.actions}>
                                        <Button
                                            type={'success'}
                                            text={getEditIcon()}
                                            rounded={true}
                                            title={'Edit Item'}
                                            onClick={() => handleEditItem(item)}
                                        />
                                        <Button
                                            type={'danger'}
                                            text={getXIcon()}
                                            rounded={true}
                                            title={'Delete Item'}
                                            onClick={() => handleDeleteItem(item)}
                                        />
                                    </div>
                                </td>
                            </tr>)}
                        </table>
                        {shouldFetchMoreItems && <div className={styles.load_more}><Button
                            type={'primary'}
                            text={'Load more'}
                            title={'Load more items'}
                            onClick={() => getItems(currentPage)}
                        /></div>}
                    </div></>}
            </Card>
        </>
    );
};