import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './helpers/ProtectedRoute';
import { Layout } from './components/layout/Layout';
import { Home } from './components/home/Home';
import { Login } from './components/login/Login';
import { Signup } from './components/signup/Signup';
import { Profile } from './components/profile/Profile';
import { Logout } from './components/logout/Logout';
import { GenerateReceipt } from './components/generateReceipt/GenerateReceipt';
import { ErrorPage } from './components/errorPage/ErrorPage';
import { Business } from './components/business/Business';
import { Items } from './components/items/Items';
import { Chat } from './components/chat/Chat';
import { FAQ } from './components/faq/FAQ';
import { ResetPassword } from './components/resetPassword/ResetPassword';
import { ContactUs } from './components/contactUs/ContactUs';
import { PrivacyPolicy } from './components/privacyPolicy/PrivacyPolicy';
import { AboutUs } from './components/aboutUs/AboutUs';
import { ViewReceipt } from './components/viewReceipt/ViewReceipt';
import { Guide } from './components/guide/Guide';
import { Reports } from './components/reports/Reports';
import { BuySubscription } from './components/buySubscriptionPlan/BuySubscription';
import { Payment } from './components/payment/Payment';
import { Terms } from './components/terms/Terms';
import { RefundPolicy } from './components/refundPolicy/RefundPolicy';
import { Notifications } from './components/notifications/Notifications';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/chat' element={<Chat />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<Terms />} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
          <Route path='/notifications' element={<Notifications />} />
          <Route path='/guide' element={<Guide />} />
          <Route path='/view-receipt/:billNo' element={<ViewReceipt />} />
          <Route path='/payment/:status' element={<ProtectedRoute>
            <Payment />
          </ProtectedRoute>} />
          <Route path='/buy-subscription-plan' element={<ProtectedRoute>
            <BuySubscription />
          </ProtectedRoute>} />
          <Route path='/profile' element={<ProtectedRoute>
            <Profile />
          </ProtectedRoute>} />
          <Route path='/my-business' element={<ProtectedRoute>
            <Business />
          </ProtectedRoute>} />
          <Route path='/my-items' element={<ProtectedRoute>
            <Items />
          </ProtectedRoute>} />
          <Route path='/create-bills' element={<ProtectedRoute>
            <GenerateReceipt />
          </ProtectedRoute>} />
          <Route path='/reports' element={<ProtectedRoute>
            <Reports />
          </ProtectedRoute>} />
          <Route path='*' element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
