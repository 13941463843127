import styles from './refundPolicy.module.css';
import { Card } from "../card/Card";
import { SITE_NAME, SUPPORT_MAIL } from '../../helpers/constants';
import { Link } from 'react-router-dom';

export const RefundPolicy = () => {
    return (
        <Card centered={true} classes={[styles.refund_policy_card]} cardHeader={<h1>Refund Policy</h1>}>
            <div>
                <h3><strong>Refund</strong></h3>
                <p>If you're unsatisfied with our product, we will provide full refund (excluding payment gateway charges + our service charges which is ₹10) only if you send an <Link to={`mailto:${SUPPORT_MAIL}`}>email</Link> to us regarding this within 3 days of purchasing our product.</p>
            </div>
            <div>
                <h3><strong>Cancellation</strong></h3>
                <p>You can cancel your subscription within 3 days of purchasing our product by sending an <Link to={`mailto:${SUPPORT_MAIL}`}>email</Link> to us. Refund will be processed as stated in refund section.</p>
            </div>
            <div>
                <p>Regards,<br />{SITE_NAME} Team</p>
            </div>
        </Card>
    );
};