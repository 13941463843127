import styles from './contactUs.module.css';
import { Card } from "../card/Card";
import { SUPPORT_MAIL } from '../../helpers/constants';
import { Link } from 'react-router-dom';

export const ContactUs = () => {
    return (
        <Card centered={true} classes={[styles.contact_card]} cardHeader={<h1>Contact Us</h1>}>
            {/* Main headline */}
            <div>
                <p>We're delighted to assist you with any questions, concerns, or feedback you may have. At BillGuru, we believe in open communication and making it easy for you to get in touch with us. Please don't hesitate to reach out using the contact information provided below:</p>
            </div>
            {/* Contact Info */}
            <div>
                <p><strong>Contact Information:</strong></p>
                <ul>
                    <li><strong>Email</strong>: <Link to={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</Link></li>
                </ul>
            </div>
            {/* Customer support */}
            <div>
                <p><strong>Customer Support:</strong></p>
                <p>Our customer support team is ready to assist you with any issues or questions you may have. Don’t hesitate to reach out for:</p>
                <ul>
                    <li>Technical support and troubleshooting.</li>
                    <li>Billing inquiries and payment assistance.</li>
                    <li>Software features and usage guidance.</li>
                </ul>
            </div>
            {/* Sales & Enquiries */}
            <div>
                <p><strong>Sales and General Inquiries:</strong></p>
                <p>For questions about our services, pricing, or any other general inquiries, our team is available to provide you with the information you need.</p>
            </div>
            {/* Feedback */}
            <div>
                <p><strong>Feedback and Suggestions:</strong></p>
                <p>We value your input and are eager to hear your thoughts on how we can improve our software and services. Your feedback helps us grow and better serve your needs.</p>
                <p>At BillGuru, we are committed to providing exceptional service and support. Your satisfaction is our top priority, and we look forward to assisting you. Thank you for choosing BillGuru as your billing software provider.</p>
            </div>

            {/* Legal name & address */}
            <div>
                <h3><strong>Legal Name & Address</strong></h3>
                <p>Name: Jay Prakash</p>
                <p>Address: Muzaffarpur, Bihar, India</p>
            </div>
        </Card>
    );
};