import styles from './signup.module.css';
import { useEffect, useState } from "react";
import { Card } from "../card/Card";
import { InputField } from "../forms/inputField/InputField";
import { Button } from "../forms/button/Button";
import { isValidEmailAddress, isValidIndianMobileNumber, makeAPICall } from "../../helpers/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { setIsLoading } from "../../redux/slices/preLoaderSlice";

export const Signup = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { userInfo } = useSelector(state => state.authReducer);
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});

    // Handle signup
    const handleSignup = async (e) => {
        e.preventDefault();

        // Clear form errors
        setErrors({});

        // Checks for firstName
        if (firstName.length === 0) {
            setErrors({ firstName: 'First name is a required field.' });
            return;
        }
        // Checks for lastName
        if (lastName.length === 0) {
            setErrors({ lastName: 'Last name is a required field.' });
            return;
        }
        // Checks for email
        if (!isValidEmailAddress(email)) {
            setErrors({ email: 'Please enter a valid email address.' });
            return;
        }
        // Checks for phone
        if (!isValidIndianMobileNumber(phone) || phone.length !== 10) {
            setErrors({ phone: 'Please enter a valid 10 digits indian mobile number.' });
            return;
        }
        // Check for password
        if (password.length < 8 ||
            password.length > 20
        ) {
            setErrors({ password: 'Please enter a password of length [8-20] characters long.' });
            return;
        }

        // Make API call to create user
        const { response, error } = await makeAPICall({
            type: 'post',
            endpoint: `/users`,
            payload: {
                firstName,
                lastName,
                email,
                phone,
                password
            },
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            toast.success(response.message);
            navigate('/login');
        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    useEffect(() => {
        if (userInfo && userInfo.accessToken) {
            navigate((state && state.backTo) || '/');
        }
    }, [userInfo]);

    return (
        <Card centered={true} cardHeader='Create a new account'>
            <form autoComplete="off">
                <InputField
                    type='text'
                    label='First Name'
                    name='firstName'
                    value={firstName}
                    placeholder='Enter your first name'
                    required={true}
                    onChange={(e) => setFirstName(e.target.value)}
                    errors={errors}
                />
                <InputField
                    type='text'
                    label='Last Name'
                    name='lastName'
                    value={lastName}
                    placeholder='Enter your last name'
                    required={true}
                    onChange={(e) => setLastName(e.target.value)}
                    errors={errors}
                />
                <InputField
                    type='email'
                    label='Email'
                    name='email'
                    value={email}
                    placeholder='Enter your email'
                    required={true}
                    onChange={(e) => setEmail(e.target.value)}
                    errors={errors}
                />
                <InputField
                    type='number'
                    label='Mobile Number'
                    name='phone'
                    value={phone}
                    placeholder='Enter your mobile number'
                    required={true}
                    onChange={(e) => setPhone(e.target.value)}
                    errors={errors}
                />
                <InputField
                    type='password'
                    label='Password'
                    name='password'
                    value={password}
                    placeholder='Enter your password'
                    required={true}
                    onChange={(e) => setPassword(e.target.value)}
                    errors={errors}
                />

                <div className={styles.login_btn_container}>
                    <Button
                        type='primary'
                        text='Signup'
                        onClick={handleSignup}
                    />
                    <div className={styles.help_text_container}>
                        <div>
                            Have an account - <Link to='/login'>Login here</Link>
                        </div>
                    </div>
                </div>
            </form>
        </Card>
    );
};