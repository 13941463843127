import { useSelector } from "react-redux";
import { Navigate, useLocation } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
    // Get user info
    const { userInfo } = useSelector(state => state.authReducer);

    // Current pathname
    const { pathname } = useLocation();

    // If not loggedin
    if (!userInfo) {
        return <Navigate to='/login' state={{ backTo: pathname }} replace={true} />;
    }

    // If loggedin
    return children;
};