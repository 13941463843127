
export const getHomeIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M7.5.5l.325-.38a.5.5 0 00-.65 0L7.5.5zm-7 6l-.325-.38L0 6.27v.23h.5zm5 8v.5a.5.5 0 00.5-.5h-.5zm4 0H9a.5.5 0 00.5.5v-.5zm5-8h.5v-.23l-.175-.15-.325.38zM1.5 15h4v-1h-4v1zm13.325-8.88l-7-6-.65.76 7 6 .65-.76zm-7.65-6l-7 6 .65.76 7-6-.65-.76zM6 14.5v-3H5v3h1zm3-3v3h1v-3H9zm.5 3.5h4v-1h-4v1zm5.5-1.5v-7h-1v7h1zm-15-7v7h1v-7H0zM7.5 10A1.5 1.5 0 019 11.5h1A2.5 2.5 0 007.5 9v1zm0-1A2.5 2.5 0 005 11.5h1A1.5 1.5 0 017.5 10V9zm6 6a1.5 1.5 0 001.5-1.5h-1a.5.5 0 01-.5.5v1zm-12-1a.5.5 0 01-.5-.5H0A1.5 1.5 0 001.5 15v-1z" fill="currentColor"></path></svg>;
};

export const getSettingsIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path clip-rule="evenodd" d="M5.944.5l-.086.437-.329 1.598a5.52 5.52 0 00-1.434.823L2.487 2.82l-.432-.133-.224.385L.724 4.923.5 5.31l.328.287 1.244 1.058c-.045.277-.103.55-.103.841 0 .291.058.565.103.842L.828 9.395.5 9.682l.224.386 1.107 1.85.224.387.432-.135 1.608-.537c.431.338.908.622 1.434.823l.329 1.598.086.437h3.111l.087-.437.328-1.598a5.524 5.524 0 001.434-.823l1.608.537.432.135.225-.386 1.106-1.851.225-.386-.329-.287-1.244-1.058c.046-.277.103-.55.103-.842 0-.29-.057-.564-.103-.841l1.244-1.058.329-.287-.225-.386-1.106-1.85-.225-.386-.432.134-1.608.537a5.52 5.52 0 00-1.434-.823L9.142.937 9.055.5H5.944z" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"></path><path clip-rule="evenodd" d="M9.5 7.495a2 2 0 01-4 0 2 2 0 014 0z" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"></path></svg>;
};

export const getEyeIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M.5 7.5l-.464-.186a.5.5 0 000 .372L.5 7.5zm14 0l.464.186a.5.5 0 000-.372L14.5 7.5zm-7 4.5c-2.314 0-3.939-1.152-5.003-2.334a9.368 9.368 0 01-1.449-2.164 5.065 5.065 0 01-.08-.18l-.004-.007v-.001L.5 7.5l-.464.186v.002l.003.004a2.107 2.107 0 00.026.063l.078.173a10.368 10.368 0 001.61 2.406C2.94 11.652 4.814 13 7.5 13v-1zm-7-4.5l.464.186.004-.008a2.62 2.62 0 01.08-.18 9.368 9.368 0 011.449-2.164C3.56 4.152 5.186 3 7.5 3V2C4.814 2 2.939 3.348 1.753 4.666a10.367 10.367 0 00-1.61 2.406 6.05 6.05 0 00-.104.236l-.002.004v.001H.035L.5 7.5zm7-4.5c2.314 0 3.939 1.152 5.003 2.334a9.37 9.37 0 011.449 2.164 4.705 4.705 0 01.08.18l.004.007v.001L14.5 7.5l.464-.186v-.002l-.003-.004a.656.656 0 00-.026-.063 9.094 9.094 0 00-.39-.773 10.365 10.365 0 00-1.298-1.806C12.06 3.348 10.186 2 7.5 2v1zm7 4.5a68.887 68.887 0 01-.464-.186l-.003.008-.015.035-.066.145a9.37 9.37 0 01-1.449 2.164C11.44 10.848 9.814 12 7.5 12v1c2.686 0 4.561-1.348 5.747-2.665a10.366 10.366 0 001.61-2.407 6.164 6.164 0 00.104-.236l.002-.004v-.001h.001L14.5 7.5zM7.5 9A1.5 1.5 0 016 7.5H5A2.5 2.5 0 007.5 10V9zM9 7.5A1.5 1.5 0 017.5 9v1A2.5 2.5 0 0010 7.5H9zM7.5 6A1.5 1.5 0 019 7.5h1A2.5 2.5 0 007.5 5v1zm0-1A2.5 2.5 0 005 7.5h1A1.5 1.5 0 017.5 6V5z" fill="currentColor"></path></svg>;
};

export const getPlusIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M7.5 1v13M1 7.5h13" stroke="currentColor"></path></svg>;
};

export const getXIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M1.5 1.5l12 12m-12 0l12-12" stroke="currentColor"></path></svg>;
};

export const getRoundedCheckIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M4 7.5L7 10l4-5m-3.5 9.5a7 7 0 110-14 7 7 0 010 14z" stroke="currentColor"></path></svg>;
};

export const getRoundedCloseIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M4.5 4.5l6 6m-6 0l6-6m-3 10a7 7 0 110-14 7 7 0 010 14z" stroke="currentColor"></path></svg>;
};

export const getRoundedDownArrowIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M4.854 6.146L4.5 5.793l-.707.707.353.354.708-.708zM7.5 9.5l-.354.354.354.353.354-.353L7.5 9.5zm3.354-2.646l.353-.354-.707-.707-.354.353.708.708zm-6.708 0l3 3 .708-.708-3-3-.708.708zm3.708 3l3-3-.708-.708-3 3 .708.708zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zM1 7.5A6.5 6.5 0 017.5 1V0A7.5 7.5 0 000 7.5h1zm-1 0A7.5 7.5 0 007.5 15v-1A6.5 6.5 0 011 7.5H0z" fill="currentColor"></path></svg>;
};

export const getRoundedUpArrowIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M10.146 8.854l.354.353.707-.707-.353-.354-.708.708zM7.5 5.5l.354-.354-.354-.353-.354.353.354.354zM4.146 8.146l-.353.354.707.707.354-.353-.708-.708zm6.708 0l-3-3-.708.708 3 3 .708-.708zm-3.708-3l-3 3 .708.708 3-3-.708-.708zM1 7.5A6.5 6.5 0 017.5 1V0A7.5 7.5 0 000 7.5h1zM7.5 14A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zm1 0A7.5 7.5 0 007.5 0v1A6.5 6.5 0 0114 7.5h1z" fill="currentColor"></path></svg>;
};

export const getRoundedRightArrowIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M6.146 10.146l-.353.354.707.707.354-.353-.708-.708zM9.5 7.5l.354.354.353-.354-.353-.354L9.5 7.5zM6.854 4.146L6.5 3.793l-.707.707.353.354.708-.708zm0 6.708l3-3-.708-.708-3 3 .708.708zm3-3.708l-3-3-.708.708 3 3 .708-.708zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zM1 7.5A6.5 6.5 0 017.5 1V0A7.5 7.5 0 000 7.5h1zM7.5 14A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zm0 1A7.5 7.5 0 0015 7.5h-1A6.5 6.5 0 017.5 14v1z" fill="currentColor"></path></svg>;
};

export const getRoundedLeftArrowIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M8.854 4.854l.353-.354-.707-.707-.354.353.708.708zM5.5 7.5l-.354-.354-.353.354.353.354L5.5 7.5zm2.646 3.354l.354.353.707-.707-.353-.354-.708.708zm0-6.708l-3 3 .708.708 3-3-.708-.708zm-3 3.708l3 3 .708-.708-3-3-.708.708zM7.5 14A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zm0-1A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0z" fill="currentColor"></path></svg>;
};

export const getDownIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M14 5l-6.5 7L1 5" stroke="currentColor" stroke-linecap="square"></path></svg>;
};

export const getUpIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M1 10l6.5-7 6.5 7" stroke="currentColor" stroke-linecap="square"></path></svg>;
};

export const getSmallDownIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M4.5 6.5l3 3 3-3" stroke="currentColor" stroke-linecap="square"></path></svg>;
};

export const getSmallUpIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M10.5 8.5l-3-3-3 3" stroke="currentColor" stroke-linecap="square"></path></svg>;
};

export const getRoundedUserIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M3 13v.5h1V13H3zm8 0v.5h1V13h-1zm-7 0v-.5H3v.5h1zm2.5-3h2V9h-2v1zm4.5 2.5v.5h1v-.5h-1zM8.5 10a2.5 2.5 0 012.5 2.5h1A3.5 3.5 0 008.5 9v1zM4 12.5A2.5 2.5 0 016.5 10V9A3.5 3.5 0 003 12.5h1zM7.5 3A2.5 2.5 0 005 5.5h1A1.5 1.5 0 017.5 4V3zM10 5.5A2.5 2.5 0 007.5 3v1A1.5 1.5 0 019 5.5h1zM7.5 8A2.5 2.5 0 0010 5.5H9A1.5 1.5 0 017.5 7v1zm0-1A1.5 1.5 0 016 5.5H5A2.5 2.5 0 007.5 8V7zm0 7A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zm0-1A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0z" fill="currentColor"></path></svg>;
}

export const getRoundedQuestionMarkIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M7.5 9V7.5H8A1.5 1.5 0 009.5 6v-.1a1.4 1.4 0 00-1.4-1.4h-.6A1.5 1.5 0 006 6m1 4.5h1m-.5 4a7 7 0 110-14 7 7 0 010 14z" stroke="currentColor"></path></svg>;
}

export const getChatIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M11.5 13.5l.157-.475-.218-.072-.197.119.258.428zm2-2l-.421-.27-.129.202.076.226.474-.158zm1 2.99l-.157.476a.5.5 0 00.631-.634l-.474.159zm-3.258-1.418c-.956.575-2.485.919-3.742.919v1c1.385 0 3.106-.37 4.258-1.063l-.516-.856zM7.5 13.99c-3.59 0-6.5-2.909-6.5-6.496H0a7.498 7.498 0 007.5 7.496v-1zM1 7.495A6.498 6.498 0 017.5 1V0A7.498 7.498 0 000 7.495h1zM7.5 1C11.09 1 14 3.908 14 7.495h1A7.498 7.498 0 007.5 0v1zM14 7.495c0 1.331-.296 2.758-.921 3.735l.842.54C14.686 10.575 15 8.937 15 7.495h-1zm-2.657 6.48l3 .99.314-.949-3-.99-.314.949zm3.631.357l-1-2.99-.948.316 1 2.991.948-.317z" fill="currentColor"></path></svg>;
}

export const getEditIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M.5 10.5l-.354-.354-.146.147v.207h.5zm10-10l.354-.354a.5.5 0 00-.708 0L10.5.5zm4 4l.354.354a.5.5 0 000-.708L14.5 4.5zm-10 10v.5h.207l.147-.146L4.5 14.5zm-4 0H0a.5.5 0 00.5.5v-.5zm.354-3.646l10-10-.708-.708-10 10 .708.708zm9.292-10l4 4 .708-.708-4-4-.708.708zm4 3.292l-10 10 .708.708 10-10-.708-.708zM4.5 14h-4v1h4v-1zm-3.5.5v-4H0v4h1z" fill="currentColor"></path></svg>;
}

export const getRoundedEditIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M4.5 8.5l-.354-.354L4 8.293V8.5h.5zm4-4l.354-.354a.5.5 0 00-.708 0L8.5 4.5zm2 2l.354.354a.5.5 0 000-.708L10.5 6.5zm-4 4v.5h.207l.147-.146L6.5 10.5zm-2 0H4a.5.5 0 00.5.5v-.5zm3 3.5A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zm0-1A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zM4.854 8.854l4-4-.708-.708-4 4 .708.708zm3.292-4l2 2 .708-.708-2-2-.708.708zm2 1.292l-4 4 .708.708 4-4-.708-.708zM6.5 10h-2v1h2v-1zm-1.5.5v-2H4v2h1z" fill="currentColor"></path></svg>;
}

export const getSmallEditIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M4.5 8.5l-.354-.354L4 8.293V8.5h.5zm4-4l.354-.354a.5.5 0 00-.708 0L8.5 4.5zm2 2l.354.354a.5.5 0 000-.708L10.5 6.5zm-4 4v.5h.207l.147-.146L6.5 10.5zm-2 0H4a.5.5 0 00.5.5v-.5zm.354-1.646l4-4-.708-.708-4 4 .708.708zm3.292-4l2 2 .708-.708-2-2-.708.708zm2 1.292l-4 4 .708.708 4-4-.708-.708zM6.5 10h-2v1h2v-1zm-1.5.5v-2H4v2h1z" fill="currentColor"></path></svg>;
}

export const getReceiptIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M1.5.5V0a.5.5 0 00-.5.5h.5zm12 0h.5a.5.5 0 00-.5-.5v.5zm0 14l-.224.447A.5.5 0 0014 14.5h-.5zm-2-1l.224-.447a.5.5 0 00-.448 0l.224.447zm-2 1l-.224.447a.5.5 0 00.448 0L9.5 14.5zm-2-1l.224-.447a.5.5 0 00-.448 0l.224.447zm-2 1l-.224.447a.5.5 0 00.448 0L5.5 14.5zm-4 0H1a.5.5 0 00.724.447L1.5 14.5zm2-1l.224-.447a.5.5 0 00-.448 0l.224.447zM1.5 1h12V0h-12v1zM13 .5v14h1V.5h-1zm.724 13.553l-2-1-.448.894 2 1 .448-.894zm-2.448-1l-2 1 .448.894 2-1-.448-.894zm-1.552 1l-2-1-.448.894 2 1 .448-.894zm-2.448-1l-2 1 .448.894 2-1-.448-.894zM2 14.5V.5H1v14h1zm3.724-.447l-2-1-.448.894 2 1 .448-.894zm-2.448-1l-2 1 .448.894 2-1-.448-.894zM4 5h2V4H4v1zm4 0h3V4H8v1zM4 8h2V7H4v1zm4 0h3V7H8v1zm0 3h3v-1H8v1z" fill="currentColor"></path></svg>;
}

export const getMobileIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M6 11.5h3m-5.5 3h8a1 1 0 001-1v-12a1 1 0 00-1-1h-8a1 1 0 00-1 1v12a1 1 0 001 1z" stroke="currentColor"></path></svg>;
}

export const getPrintIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M3.5 12.5h-2a1 1 0 01-1-1v-4a1 1 0 011-1h12a1 1 0 011 1v4a1 1 0 01-1 1h-2m-8-6v-5a1 1 0 011-1h6a1 1 0 011 1v5m-8 4h8v4h-8v-4z" stroke="currentColor"></path></svg>;
}

export const getDownloadIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M7.5 10.5l-3.25-3m3.25 3l3-3m-3 3V1m6 6v6.5h-12V7" stroke="currentColor"></path></svg>;
}

export const getWhatsappIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M1.9 11.7l.447.224.138-.277L2.3 11.4l-.4.3zm1.4 1.4l.3-.4-.247-.185-.277.138.224.447zM.5 14.5l-.447-.224a.5.5 0 00.67.671L.5 14.5zm4-10l-.277-.416A.5.5 0 004 4.5h.5zm6 6v.5a.5.5 0 00.416-.223L10.5 10.5zM6.254 5.026l.493-.083-.493.083zm.14.836l-.493.082.493-.082zm-.432.997l.277.416-.277-.416zm4.68 3.428l.416.277-.416-.277zm-.668-1.541l.083-.493-.083.493zm-.836-.14l-.082.493.082-.493zm-.997.432l-.416-.277.416.277zM0 7.5c0 1.688.558 3.247 1.5 4.5l.8-.6A6.47 6.47 0 011 7.5H0zM7.5 0A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zM15 7.5A7.5 7.5 0 007.5 0v1A6.5 6.5 0 0114 7.5h1zM7.5 15A7.5 7.5 0 0015 7.5h-1A6.5 6.5 0 017.5 14v1zM3 13.5A7.47 7.47 0 007.5 15v-1a6.469 6.469 0 01-3.9-1.3l-.6.8zM.723 14.947l2.8-1.4-.448-.894-2.8 1.4.448.894zm.729-3.47l-1.4 2.8.894.447 1.4-2.8-.894-.447zM4 4.5v1h1v-1H4zM9.5 11h1v-1h-1v1zM4 5.5A5.5 5.5 0 009.5 11v-1A4.5 4.5 0 015 5.5H4zm.777-.584l.214-.142-.555-.832-.213.142.554.832zm.984.192l.14.836.986-.164-.14-.837-.986.165zm-.076 1.335l-.962.641.554.832.962-.641-.554-.832zm.216-.499a.5.5 0 01-.216.499l.554.832a1.5 1.5 0 00.648-1.495l-.986.164zm-.91-1.17a.5.5 0 01.77.334l.986-.165a1.5 1.5 0 00-2.311-1.001l.555.832zm5.925 6.003l.142-.213-.832-.555-.142.214.832.554zm-.86-2.524l-.836-.14-.164.987.836.139.165-.986zm-2.33.508l-.642.962.832.554.641-.962-.832-.554zm1.494-.648a1.5 1.5 0 00-1.495.648l.832.554a.5.5 0 01.499-.216l.164-.986zm1.838 2.451a1.5 1.5 0 00-1.001-2.311l-.165.986a.5.5 0 01.334.77l.832.555z" fill="currentColor"></path></svg>;
}

export const getShopIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M1.5 2.5V2a.5.5 0 00-.49.402l.49.098zm12 0l.49-.098A.5.5 0 0013.5 2v.5zm1 5V8a.5.5 0 00.49-.598l-.49.098zm-14 0l-.49-.098A.5.5 0 00.5 8v-.5zm3 3H3v.5h.5v-.5zm8 0v.5h.5v-.5h-.5zM0 15h15v-1H0v1zm1-7.5v7h1v-7H1zm12 0v7h1v-7h-1zM1.5 3h12V2h-12v1zm11.51-.402l1 5 .98-.196-1-5-.98.196zM14.5 7H.5v1h14V7zM.99 7.598l1-5-.98-.196-1 5 .98.196zM1 1h13V0H1v1zm2 6.5v3h1v-3H3zm.5 3.5h8v-1h-8v1zm8.5-.5v-3h-1v3h1z" fill="currentColor"></path></svg>;
}

export const getOrderedListIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M.5 13.5l-.354-.354A.5.5 0 00.5 14v-.5zm1-11H2V2h-.5v.5zM5 8h10V7H5v1zm0-4h10V3H5v1zm0 8h10v-1H5v1zm-2 1H.5v1H3v-1zm-2.146.854l1.792-1.793-.707-.707-1.793 1.792.708.708zM1.793 10H1.5v1h.293v-1zM1.5 10A1.5 1.5 0 000 11.5h1a.5.5 0 01.5-.5v-1zM3 11.207C3 10.54 2.46 10 1.793 10v1c.114 0 .207.093.207.207h1zm-.354.854c.227-.227.354-.534.354-.854H2a.207.207 0 01-.06.147l.706.707zM0 6h3V5H0v1zm2-.5v-3H1v3h1zM1.5 2H0v1h1.5V2z" fill="currentColor"></path></svg>;
}

export const getContactIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M2 12.5v.5h1v-.5H2zm5 0v.5h1v-.5H7zm-4 0V12H2v.5h1zm4-.5v.5h1V12H7zm-2-2a2 2 0 012 2h1a3 3 0 00-3-3v1zm-2 2a2 2 0 012-2V9a3 3 0 00-3 3h1zm2-8a2 2 0 00-2 2h1a1 1 0 011-1V4zm2 2a2 2 0 00-2-2v1a1 1 0 011 1h1zM5 8a2 2 0 002-2H6a1 1 0 01-1 1v1zm0-1a1 1 0 01-1-1H3a2 2 0 002 2V7zM1.5 3h12V2h-12v1zm12.5.5v8h1v-8h-1zm-.5 8.5h-12v1h12v-1zM1 11.5v-8H0v8h1zm.5.5a.5.5 0 01-.5-.5H0A1.5 1.5 0 001.5 13v-1zm12.5-.5a.5.5 0 01-.5.5v1a1.5 1.5 0 001.5-1.5h-1zM13.5 3a.5.5 0 01.5.5h1A1.5 1.5 0 0013.5 2v1zm-12-1A1.5 1.5 0 000 3.5h1a.5.5 0 01.5-.5V2zM9 6h3V5H9v1zm0 3h3V8H9v1zm-9 6h15v-1H0v1zM3 0v2.5h1V0H3zm8 0v2.5h1V0h-1z" fill="currentColor"></path></svg>;
}

export const getShareIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M4.5 7.5h4M11 4L8.5 7.495 11 11m3.5-8.501a2 2 0 01-4 0 2 2 0 014 0zm0 9.993a2 2 0 01-4 0 2 2 0 014 0zm-10-4.997a2 2 0 01-4 0 2 2 0 014 0z" stroke="currentColor" stroke-linecap="square"></path></svg>;
}

export const getSecurityIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M7.5 1.5l.248-.434L7.5.924l-.248.142.248.434zm-7 4l-.248-.434L0 5.21v.29h.5zm14 0h.5v-.29l-.252-.144-.248.434zm-7 10l-.137.48.137.04.137-.04-.137-.48zM7 11l-.32.384.392.327.318-.399L7 11zm.252-9.934l-7 4 .496.868 7-4-.496-.868zM0 5.5v.72h1V5.5H0zm15 .72V5.5h-1v.72h1zm-.252-1.154l-7-4-.496.868 7 4 .496-.868zM7.638 15.98A10.152 10.152 0 0015 6.22h-1a9.151 9.151 0 01-6.637 8.8l.274.96zM0 6.22a10.15 10.15 0 007.363 9.76l.274-.96A9.151 9.151 0 011 6.22H0zm3.68 2.664l3 2.5.64-.768-3-2.5-.64.768zm3.71 2.428l4-5-.78-.624-4 5 .78.624z" fill="currentColor"></path></svg>;
}

export const getLoginIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M10.5 7.5l-3 3.25m3-3.25l-3-3m3 3H1m6-6h6.5v12H7" stroke="currentColor"></path></svg>;
}

export const getLogoutIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M10.5 7.5l-3 3.25m3-3.25l-3-3m3 3H1m6-6h6.5v12H7" stroke="currentColor"></path></svg>;
}

export const getUserIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M10.5 7.5l-3 3.25m3-3.25l-3-3m3 3H1m6-6h6.5v12H7" stroke="currentColor"></path></svg>;
}

export const getBulbOnIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M4.076 6.47l.495.07-.495-.07zm-.01.07l-.495-.07.495.07zm6.858-.07l.495-.07-.495.07zm.01.07l-.495.07.495-.07zM9.5 12.5v.5a.5.5 0 00.5-.5h-.5zm-4 0H5a.5.5 0 00.5.5v-.5zm-.745-3.347l.396-.306-.396.306zm5.49 0l-.396-.306.396.306zM6 15h3v-1H6v1zM3.58 6.4l-.01.07.99.14.01-.07-.99-.14zM7.5 3a3.959 3.959 0 00-3.92 3.4l.99.14A2.959 2.959 0 017.5 4V3zm3.92 3.4A3.959 3.959 0 007.5 3v1a2.96 2.96 0 012.93 2.54l.99-.14zm.01.07l-.01-.07-.99.14.01.07.99-.14zm-.79 2.989c.63-.814.948-1.875.79-2.99l-.99.142a2.951 2.951 0 01-.59 2.236l.79.612zM9 10.9v1.6h1v-1.599H9zm.5 1.1h-4v1h4v-1zm-3.5.5v-1.599H5V12.5h1zM3.57 6.47a3.951 3.951 0 00.79 2.989l.79-.612a2.951 2.951 0 01-.59-2.236l-.99-.142zM6 10.9c0-.823-.438-1.523-.85-2.054l-.79.612c.383.495.64.968.64 1.442h1zm3.85-2.054C9.437 9.378 9 10.077 9 10.9h1c0-.474.257-.947.64-1.442l-.79-.612zM7 0v2h1V0H7zM0 8h2V7H0v1zm13 0h2V7h-2v1zM3.354 3.646l-1.5-1.5-.708.708 1.5 1.5.708-.708zm9 .708l1.5-1.5-.708-.708-1.5 1.5.708.708z" fill="currentColor"></path></svg>;
}

export const getBulbOffIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M4.076 6.47L3.58 6.4l.495.07zm-.01.07l.495.07-.495-.07zm6.858-.07l-.495.07.495-.07zm.01.07l.495-.07-.495.07zM9.5 12.5v.5a.5.5 0 00.5-.5h-.5zm-4 0H5a.5.5 0 00.5.5v-.5zm-.745-3.347l.396-.306-.396.306zm5.49 0l-.396-.306.396.306zM6 15h3v-1H6v1zM3.58 6.4l-.01.07.99.14.01-.07-.99-.14zM7.5 3a3.959 3.959 0 00-3.92 3.4l.99.14A2.959 2.959 0 017.5 4V3zm3.92 3.4A3.959 3.959 0 007.5 3v1a2.96 2.96 0 012.93 2.54l.99-.14zm.01.07l-.01-.07-.99.14.01.07.99-.14zm-.79 2.989c.63-.814.948-1.875.79-2.99l-.99.142a2.951 2.951 0 01-.59 2.236l.79.612zM9 10.9v1.6h1v-1.599H9zm.5 1.1h-4v1h4v-1zm-3.5.5v-1.599H5V12.5h1zM3.57 6.47a3.951 3.951 0 00.79 2.989l.79-.612a2.951 2.951 0 01-.59-2.236l-.99-.142zM6 10.9c0-.823-.438-1.523-.85-2.054l-.79.612c.383.495.64.968.64 1.442h1zm3.85-2.054C9.437 9.378 9 10.077 9 10.9h1c0-.474.257-.947.64-1.442l-.79-.612z" fill="currentColor"></path></svg>;
}

export const getBellIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M1 10.5h13m-11.5 0v-5a5 5 0 0110 0v5m-7 1.5v.5a2 2 0 104 0V12" stroke="currentColor"></path></svg>;
}

export const getWalletIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M13.5 6V2.5a1 1 0 00-1-1h-11a1 1 0 00-1 1v10a1 1 0 001 1h11a1 1 0 001-1V9m.93-3.5H9.5a2 2 0 100 4h4.93a.07.07 0 00.07-.07V5.57a.07.07 0 00-.07-.07z" stroke="currentColor"></path></svg>;
}

export const getBookIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M1.5.5V0a.5.5 0 00-.5.5h.5zm0 13H1a.5.5 0 00.5.5v-.5zM4 0v15h1V0H4zM1.5 1h10V0h-10v1zM13 2.5v9h1v-9h-1zM11.5 13h-10v1h10v-1zm-9.5.5V.5H1v13h1zm11-2a1.5 1.5 0 01-1.5 1.5v1a2.5 2.5 0 002.5-2.5h-1zM11.5 1A1.5 1.5 0 0113 2.5h1A2.5 2.5 0 0011.5 0v1zM7 5h4V4H7v1z" fill="currentColor"></path></svg>;
}

export const getBarChartIcon = (width = 15, height = 15) => {
    return <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}><path d="M0 14.5h15M5.5 12V6m4 6V3m4 9V0m-12 9v3" stroke="currentColor"></path></svg>;
}

