import { createSlice } from '@reduxjs/toolkit';

export const preLoaderSlice = createSlice({
    name: 'preLoaderSlice',
    initialState: {
        isLoading: false
    },
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    }
});

// Export actions
export const { setIsLoading } = preLoaderSlice.actions;

// Export reducer
export default preLoaderSlice.reducer;