import styles from './login.module.css';
import { useEffect, useState } from "react";
import { Card } from "../card/Card";
import { InputField } from "../forms/inputField/InputField";
import { Button } from "../forms/button/Button";
import { isValidEmailAddress, isValidIndianMobileNumber } from "../../helpers/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../customHooks/useAuth";
import { useSelector } from "react-redux";

export const Login = () => {
    const { state } = useLocation();
    const { userInfo } = useSelector(state => state.authReducer);
    const navigate = useNavigate();
    const { doLogin, setupForSilentRefresh } = useAuth();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});

    // Handle login
    const handleLogin = async (e) => {
        e.preventDefault();

        // Clear form errors
        setErrors({});

        // Checks for username
        if (!isValidIndianMobileNumber(userName) &&
            !isValidEmailAddress(userName)
        ) {
            setErrors({ userName: 'Please enter a valid username.' });
            return;
        }

        // Check for password
        if (password.length < 8 ||
            password.length > 20
        ) {
            setErrors({ password: 'Please enter a valid password.' });
            return;
        }

        // Do login
        await doLogin(userName, password);
    };

    useEffect(() => {
        if (userInfo && userInfo.accessToken) {
            navigate((state && state.backTo) || '/create-bills');
        }
    }, [userInfo]);

    useEffect(() => {
        // Login & set up for silent refresh
        setupForSilentRefresh();
    }, []);

    return (
        <Card centered={true} cardHeader='Login'>
            <form autoComplete="off">
                <InputField
                    type='text'
                    label='Username'
                    name='userName'
                    value={userName}
                    placeholder='Enter your user name'
                    required={true}
                    onChange={(e) => setUserName(e.target.value)}
                    errors={errors}
                />
                <InputField
                    type='password'
                    label='Password'
                    name='password'
                    value={password}
                    placeholder='Enter your password'
                    required={true}
                    onChange={(e) => setPassword(e.target.value)}
                    errors={errors}
                />
                <div className={styles.login_btn_container}>
                    <Button
                        type='primary'
                        text='Login'
                        onClick={handleLogin}
                    />
                    <div className={styles.help_text_container}>
                        <div>
                            Forgot password - <Link to='/reset-password'>Reset here</Link>
                        </div>
                        {/* <div>
                            Don't have an account - <Link to='/signup'>Create here</Link>
                        </div> */}
                    </div>
                </div>
            </form>

            {/* Separator */}
            <div className='separator'></div>
            <Link to='/signup' className={styles.create_account}>
                <Button
                    type='primary'
                    text='Create New Account'
                />
            </Link>
        </Card>
    );
};