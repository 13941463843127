import { useEffect, useState } from 'react';
import { Receipt } from '../receipt/Receipt';
import styles from './viewReceipt.module.css';
import { useParams } from 'react-router-dom';
import { makeAPICall } from '../../helpers/utils';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/slices/preLoaderSlice';
import toast from 'react-hot-toast';
import { Button } from '../forms/button/Button';
import { getDownloadIcon } from '../../helpers/iconUtils';
import { Card } from '../card/Card';
import html2canvas from 'html2canvas';
import { SITE_NAME } from '../../helpers/constants';

export const ViewReceipt = () => {
    const { billNo } = useParams();
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [receiptData, setReceiptData] = useState({});

    // Fetch receipt data
    const fetchReceiptData = async (id) => {
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/receipts/${id}`,
            dispatch,
            setIsLoading
        });

        if (response && response.success) {
            setData(response.data);
            setReceiptData(JSON.parse(response.data.receiptData));
        } else {
            toast.error(error.message || error || 'Something went wrong while saving item.');
        }
    };

    useEffect(() => {
        fetchReceiptData(billNo);
    }, []);

    // Get image
    const getImage = async (element) => {
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL('image/png', 1.0);
        return image;
    };

    // Handle download
    const handleDownload = async () => {
        try {
            const element = document.querySelector('#receipt');
            element.style.padding = '0.5rem';
            const image = await getImage(element);
            element.style.padding = '0';
            const downloadLink = window.document.createElement('a');
            downloadLink.style.display = 'none';
            downloadLink.download = `${SITE_NAME}-receipt-${new Date().getTime()}.png`;
            downloadLink.href = image;
            
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            downloadLink.remove();
        } catch (err) {
            toast.error('Something went wrong.');
        }
    };

    return (
        <Card centered={true} fullWidth={true} cardHeader={'Receipt'}>
            {Object.keys(data).length && Object.keys(receiptData).length ? <>
                <div className={styles.receipt_actions}>
                    <Button
                        type="danger"
                        text={<>Download {getDownloadIcon(20, 20)}</>}
                        onClick={handleDownload}
                    />
                </div>

                <div className={styles.receipt_preview}>
                    {/* Receipt */}
                    <Receipt data={{
                        businessDetails: receiptData.businessDetails,
                        customerDetails: receiptData.customerDetails,
                        billDate: receiptData?.billDate,
                        items: receiptData.items,
                        subTotal: receiptData.subTotal,
                        total: receiptData.total,
                        billId: data.id
                    }}
                    />
                </div>
            </> : <p>Loading receipt...</p>}
        </Card>
    );
};