import styles from './privacyPolicy.module.css';
import { Card } from "../card/Card";
import { SUPPORT_MAIL } from '../../helpers/constants';
import { Link } from 'react-router-dom';

export const PrivacyPolicy = () => {
    return (
        <Card centered={true} classes={[styles.privacy_card]} cardHeader={<h1>Privacy Policy</h1>}>
            <div>
                <p>At BillGuru, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our website, software, or services.</p>
            </div>
            <div>
                <p><strong>1. Information We Collect</strong></p>
                <p>We may collect the following types of information:</p>
                <ul>
                    <li><strong>Personal Information</strong>: When you sign up for our services, we may collect personal details, including your name, email address, phone number, and business information.</li>
                    <li><strong>Billing Information</strong>: To process payments, we may collect billing and payment information, including credit card details.</li>
                    <li><strong>Usage Data</strong>: We collect information about how you use our services, such as your interactions with the software and website, the features you access, and the pages you visit.</li>
                    <li><strong>Device Information</strong>: We may collect information about your device, including its type, operating system, and browser.</li>
                </ul>
            </div>
            <div>
                <p><strong>2. How We Use Your Information</strong></p>
                <p>We use the information we collect for various purposes, including:</p>
                <ul>
                    <li>Providing and maintaining our services.</li>
                    <li>Processing payments and invoices.</li>
                    <li>Improving and enhancing our software and website.</li>
                    <li>Responding to your requests and providing customer support.</li>
                    <li>Sending updates, promotions, and other information related to our services.</li>
                    <li>Analyzing usage patterns to optimize the user experience.</li>
                </ul>
            </div>
            <div>
                <p><strong>3. Data Security</strong></p>
                <p>We take data security seriously. BillGuru employs industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, and destruction. Your payment information is encrypted to ensure its confidentiality.</p>
            </div>
            <div>
                <p><strong>4. Sharing Your Information</strong></p>
                <p>We do not share your personal information with third parties, except in the following circumstances:</p>
                <ul>
                    <li>With your consent.</li>
                    <li>When required by law or to protect our legal rights.</li>
                    <li>With trusted service providers, such as payment processors, for the purpose of providing our services.</li>
                    <li>In the event of a business transaction, such as a merger, acquisition, or sale of assets, your information may be transferred to the new entity.</li>
                </ul>
            </div>
            <div>
                <p><strong>5. Your Choices</strong></p>
                <p>You have the right to access, correct, or delete your personal information. You can update your information by logging into your BillGuru account. If you wish to delete your account or have any questions about your data, please contact us at <Link to={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</Link>.</p>
            </div>
            <div>
                <p><strong>6. Cookies and Tracking Technologies</strong></p>
                <p>We use cookies and similar tracking technologies to collect information about your interactions with our website. You can control the use of cookies through your browser settings.</p>
            </div>
            <div>
                <p><strong>7. Changes to the Privacy Policy</strong></p>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on our website, and the date of the latest revision will be indicated.</p>
            </div>
            <div>
                <p><strong>8. Contact Us</strong></p>
                <p>If you have any questions or concerns about our Privacy Policy or the way we handle your information, please contact us at <Link to={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</Link>.</p>
                <p>Thank you for choosing BillGuru. We are committed to protecting your privacy and providing you with secure and reliable billing software. Your trust is important to us, and we will continue to make your data security a top priority.</p>
            </div>
        </Card>
    );
};